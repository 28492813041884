import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Heading from "../../components/sections/Heading/Heading";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import DataGrid from "../../components/atoms/Table/DataGrid";
import {
  CREDIT_APPROVAL_STATUS,
  CREDIT_HOLD_STATUS,
  CREDIT_REASON,
  CREDIT_TYPE,
} from "../../helpers/const";
import { endpoints } from "../../api/endpoints";
import { getCreditsByFilter } from "../../store/slices/Credits/CreditSlice";

/**
 * These header attributes can be changed at any point if needed and it will automatically update below.
 * Be careful changing the ID as that is actually used to set values when filtering
 */
const newHeaderAttributes = [
  {
    id: "credit_memo_num",
    label: "Memo #",
    tooltip: "Credit Memo Number",
    sortable: true,
    searchable: {
      label: "Search by Credit Memo Number",
      type: "text",
      width: 4,
    },
    width: "5%",
  },
  {
    id: "credit_type",
    label: "Credit Type",
    sortable: true,
  },
  {
    id: "credit_reason",
    label: "Credit Reason",
    sortable: true,
  },
  {
    id: "ref_invoice",
    label: "Ref Invoice",
    hidden: true,
    searchable: {
      label: "Search by Reference Invoice Number",
      type: "text",
      width: 4,
    },
  },
  {
    id: "po",
    label: "PO",
    sortable: false,
  },
  {
    id: "date_entered",
    label: "Date",
    tooltip: "Date of Order",
    sortable: true,
    width: "10%",
  },
  {
    id: "total",
    label: "Total",
    sortable: true,
  },
  {
    id: "agent",
    label: "Agent",
    sortable: true,
  },
  {
    id: "return_address",
    label: "Return Information",
    sortable: false,
    width: "20%",
  },
  {
    id: "status",
    label: "Status",
    sortable: true,
  },
  {
    id: "",
    label: "",
    sortable: false,
  },
];

/**
 * Component to fetch and render the list of credits based on parameters
 *
 * @returns CreditSearch
 */
const CreditSearch = () => {
  const [dataList, setDataList] = useState([]);
  //The total count of credits that fit into this filter, even if not all are displayed since we paginate at 50
  const [totalCredits, setTotalCredits] = useState(0);

  // Redux
  const dispatch = useDispatch();

  const fetchCredits = useCallback(
    async ({
      credit_memo_num,
      ref_invoice,
      return_address,
      to,
      status,
      from,
      selected,
      page,
      order_by,
      sort_order,
    }) => {
      /**
       * This can look intimidating but all this does is get the objects by passing filter params to the API
       *  then
       * manually sets each field in header attributes if it exists
       */
      dispatch(
        getCreditsByFilter({
          credit_memo_num,
          ref_invoice,
          return_address,
          to,
          status,
          from,
          selected,
          page,
          order_by,
          sort_order,
        })
      ).then((response) => {
        if (!response?.error) {
          setTotalCredits(response.payload.count);
          setDataList(
            response?.payload.results.map((value) => ({
              id: value.credit_memo_num,
              credit_memo_num: (
                <Link
                  to={
                    endpoints.CREDIT_ENTRY + `/credit/${value.credit_memo_num}`
                  }
                >
                  {value.credit_memo_num} (Ref Inv:{" "}
                  {value.reference_invoice_num})
                </Link>
              ),
              credit_type: CREDIT_TYPE.filter(
                (type) => type.id === parseInt(value.credit_type)
              )[0].label,
              credit_reason: CREDIT_REASON.filter(
                (reason) => reason.id === parseInt(value.credit_reason)
              )[0].label,
              po: value.apply_to_po,
              date_entered: value.date_entered,
              total: value.memo_amount,
              agent: value.entered_by,
              return_address: `${value.return_customer_name}
                        ${value.return_street}
                        ${value.return_route}
                        ${
                          value.return_city.length > 1
                            ? value.return_city + ", "
                            : ""
                        } ${value.return_state} ${value.return_zip}`,
              status:
                value.status === "0"
                  ? CREDIT_APPROVAL_STATUS.filter(
                      (status) => status.id === parseInt(value.status)
                    )[0].label +
                    " - " +
                    CREDIT_HOLD_STATUS.filter(
                      (status) => status.id === parseInt(value.hold_status)
                    )[0].label
                  : CREDIT_APPROVAL_STATUS.filter(
                      (status) => status.id === parseInt(value.status)
                    )[0].label,
              actions: (
                <Link
                  to={
                    endpoints.CREDIT_ENTRY + `/credit/${value.credit_memo_num}`
                  }
                >
                  View
                </Link>
              ),
            }))
          );
        }
      });
    },
    []
  );

  /**
   * This function is probably not necessary but I do it to allow the data to be tracked easier,
   * you could just explode customParameters but doing like this,
   * once you get to creditSearch you can assume all of your data is either valid or undefined
   */
  const prepareFetchCredits = useCallback(
    async ({ page = 0, orderBy, sortOrder, customParameters, stat }) => {
      let credit_memo_num;
      let ref_invoice;
      let return_address;
      let from = null;
      let to = null;

      if (customParameters) {
        customParameters.forEach((c) => {
          switch (c?.id) {
            case "credit_memo_num":
              credit_memo_num = c.value;
              break;
            case "ref_invoice":
              ref_invoice = c.value;
              break;
            case "return_address":
              return_address = c.value;
              break;
            case "dates":
              from = dayjs(c.value[0]).format("YYYY-MM-DD");
              to = dayjs(c.value[1]).format("YYYY-MM-DD");
              break;
          }
        });
      }

      await fetchCredits({
        page: page + 1,
        order_by: orderBy,
        sort_order: sortOrder === "desc" ? "dsc" : "asc",
        credit_memo_num,
        ref_invoice,
        return_address,
        status: stat,
        to,
        from,
      });
    },
    [fetchCredits]
  );

  return (
    <div>
      <Heading title="Credits" />

      <div className="mt-5">
        <div className="mt-5">
          {/* This is a custom component not a MUI component */}
          <DataGrid
            headerAttributes={newHeaderAttributes}
            dataList={dataList}
            hideId={true}
            rowsPerPage={50}
            total={totalCredits}
            defaultOrder="asc"
            defaultOrderBy="status"
            isCredits={true}
            fetchData={prepareFetchCredits}
            dateFilter={{
              show: true,
              inputs: [
                {
                  label: "From",
                  name: "from",
                },
                {
                  label: "To",
                  name: "to",
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreditSearch;
