import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import {
  ADJUSTMENT_CREDIT,
  REBILL_CREDIT,
  STANDARD_CREDIT,
} from "../../../../helpers/const";
import { blue, red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCreditItem,
  removeCreditItem,
} from "../../../../store/slices/Credits/CreditSlice";
import ImageUpload from "../ImageUpload";
/**
 *
 * @param itemKey the unique item key defined in LineItem or when the item is loaded, this is very important as it is used for adding and deleting line items
 * @param qtyShipped this is not editable and is just for making sure the qty entered does not exceed the qty on the original order
 * @param totalReturn used to compare the total accross seperate creditLines to make sure the quantity allowed is not exceeded
 * @param disabled this is defined in CreditEntry and passed down, it determines whether the input is editable or not
 *
 * @returns
 */
const CreditLine = ({ itemKey, qtyShipped, disabled, totalReturn }) => {
  const { creditType, problemList, creditItems } = useSelector(
    (state) => state.credits
  );

  const dispatch = useDispatch();
  const [totalCredit, setTotalCredit] = useState(0.0);

  //We want to have a copy of our item here, so go ahead and
  const [item, setItem] = useState(
    creditItems.filter((item) => item.key === itemKey)[0]
  );

  /**
   * It is possible to just update the item, but to avoid any mismatch key which are CRITICAL to the app working,
   * I just remove and reinsert the credit item each time it is changed. If the creditItems ever became large this could be problematic
   * but credits are never large enough to worry about performance
   */
  useEffect(() => {
    let temp = { ...item };
    dispatch(removeCreditItem(itemKey));
    dispatch(addCreditItem(temp));
  }, [item]);

  // Item managers
  const handleProblemChange = (value) => {
    const val = parseInt(value);

    const tempProb = problemList.filter((prob) => prob.problem_num === val)[0];
    setItem({
      ...item,
      added_to_stock: tempProb.stock_item,
      manufacturer_defect: tempProb.manufacturer_defect,
      problem_num: val,
    });
  };

  const handlePercentChange = (value) => {
    if (!value) {
      setItem({
        ...item,
        percent_allowed: 0,
        credit_amount: 0,
      });
    } else {
      let val = parseFloat(value.replace(/[^0-9.]/g, ""));
      if (val > 100) {
        val = 100;
      } else if (val < 0) {
        val = 0;
      }

      setItem({
        ...item,
        percent_allowed: val,
        credit_amount: item.item_price * item.return * (val / 100),
      });
    }
  };

  const handleCommentChange = (value) => {
    setItem({
      ...item,
      comments: value,
    });
  };

  const handleReturnChange = (value) => {
    let val = value ? parseInt(value) : 0;

    //This is to make sure the line items never exceed the qty allowed by the original order
    if (val + totalReturn > qtyShipped) {
      val =
        qtyShipped - totalReturn > 0 ? qtyShipped - totalReturn : item.return;
      if (val === item.return) {
        return;
      }
    }

    setItem({
      ...item,
      return: val,
      credit_amount: item.item_price * val * (item.percent_allowed / 100),
    });
  };

  const handleCreditAmountChange = (value) => {
    setTotalCredit(value);
    setItem({
      ...item,
      credit_amount: value,
    });
  };

  const handleDefectChange = (checked) => {
    setItem({
      ...item,
      manufacturer_defect: checked ? "Y" : "N",
    });
  };

  const handleAddedToStockChange = (checked) => {
    setItem({
      ...item,
      added_to_stock: checked ? "Y" : "N",
    });
  };

  const handleInHouseChange = (checked) => {
    setItem({
      ...item,
      in_house: checked ? "Y" : "N",
    });
  };

  const handleSerialNumChange = (val) => {
    setItem({
      ...item,
      serial_num: val,
    });
  };

  const problemProps = {
    options: problemList,
    getOptionLabel: (option) => option.problem_num + " - " + option.description,
  };

  return (
    <div className="credit-item-dropdown">
      <Row>
        <Col>
          <ImageUpload
            disabled={disabled}
            key={item.key.split("-")[0]}
            itemKey={item.key.split("-")[0]}
            sequence={item.key.split("-")[1]}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-5">
          <b>Total Credit: </b> {item.credit_amount}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="info-header">Percent Allowed</div>
          <TextField
            disabled={disabled}
            value={item.percent_allowed}
            helperText="Maximum Allowed: 100.00"
            onChange={(e) => handlePercentChange(e.target.value)}
          ></TextField>
        </Col>
        <Col
          className={
            [STANDARD_CREDIT.id, REBILL_CREDIT.id].includes(creditType)
              ? ""
              : "hidden "
          }
        >
          <div className="info-header">Credit Qty</div>
          <TextField
            disabled={disabled}
            value={item.return}
            helperText={"Maximum Allowed: " + (qtyShipped - totalReturn)}
            onChange={(e) => handleReturnChange(e.target.value)}
          ></TextField>
        </Col>
        <Col className={creditType === ADJUSTMENT_CREDIT.id ? "" : "hidden "}>
          <div className="info-header">New Item Price</div>
          <TextField
            disabled={disabled}
            value={totalCredit}
            helperText={"Maximum Allowed: " + item.item_price}
            onChange={(e) => handleCreditAmountChange(e.target.value)}
          ></TextField>
        </Col>

        <Col
          className={
            "g-line" + (creditType === STANDARD_CREDIT.id ? "" : "hidden ")
          }
        >
          <FormGroup className="mt-5">
            <FormControlLabel
              onChange={(e) => handleInHouseChange(e.target.checked)}
              label="In House"
              checked={item.in_house === "Y" ? true : false}
              control={
                <Checkbox
                  disabled={disabled}
                  sx={{
                    color: red[800],
                    "&.Mui-checked": {
                      color: blue[600],
                    },
                  }}
                />
              }
            />
          </FormGroup>
        </Col>
        <Col className={creditType === STANDARD_CREDIT.id ? "" : "hidden "}>
          <FormGroup className="mt-5">
            <FormControlLabel
              onChange={(e) => handleAddedToStockChange(e.target.checked)}
              label="Added To Stock"
              checked={item.added_to_stock === "Y" ? true : false}
              control={
                <Checkbox
                  disabled={disabled}
                  sx={{
                    color: red[800],
                    "&.Mui-checked": {
                      color: blue[600],
                    },
                  }}
                />
              }
            />
          </FormGroup>
        </Col>
        <Col className={creditType === STANDARD_CREDIT.id ? "" : "hidden "}>
          <FormGroup className="mt-5">
            <FormControlLabel
              onChange={(e) => handleDefectChange(e.target.checked)}
              label="Manufacturer Defect"
              checked={item.manufacturer_defect === "Y" ? true : false}
              control={
                <Checkbox
                  disabled={disabled}
                  sx={{
                    color: red[800],
                    "&.Mui-checked": {
                      color: blue[600],
                    },
                  }}
                />
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="credit-comments">
          <div className="info-header">Serial Number</div>
          <TextField
            disabled={disabled}
            inputProps={{ maxLength: 20 }}
            value={item.serial_num}
            className="problems"
            onChange={(e) => handleSerialNumChange(e.target.value)}
            helperText={
              "Characters Remaining: " +
              (20 - item.serial_num.length) +
              "/" +
              20
            }
          ></TextField>
        </Col>
      </Row>
      <Row>
        <Col className="credit-comments">
          <div className="info-header">Comments</div>
          <TextField
            disabled={disabled}
            inputProps={{ maxLength: 80 }}
            value={item.comments}
            className="problems"
            onChange={(e) => handleCommentChange(e.target.value)}
            helperText={
              "Characters Remaining: " + (80 - item.comments.length) + "/" + 80
            }
          ></TextField>
        </Col>
        <Col>
          <div className="info-header">Problems</div>
          <Autocomplete
            disabled={disabled}
            disableClearable
            {...problemProps}
            className="autocomplete-input-credit"
            onChange={(e) =>
              handleProblemChange(e.target.textContent.split(" ")[0])
            }
            value={
              problemList.filter(
                (prob) => prob.problem_num === item.problem_num
              )[0]
            }
            isOptionEqualToValue={(option, value) =>
              option.problem_num === value.problem_num
            }
            renderInput={(params) => (
              <TextField
                className="autocomplete-problems"
                {...params}
                variant="standard"
              />
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreditLine;
