import { TextField } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setDropShip } from "../../../store/slices/Credits/CreditSlice";
import { STANDARD_CREDIT } from "../../../helpers/const";

/**
 * 
 * @param show determines whether this component will be visible at all, this is toggled by the CreditInputToggle
 * @param disabled if the CreditEntry logic determines the user should not be able to edit these fields, this will be true
 *  
 * @returns Component for customer information
 */
const CreditInputCustomer = ({
    show,
    disabled
}) => {
    // Drop ship could also be named "returnAddress", any time the return address is updated we just use this field since the drop ship is already kind of a custom variable
    const { creditOrder, dropShip, creditType } = useSelector((state) => state.credits)

    const dispatch = useDispatch()

    // There are only six fields that need to be updated and they all just need to be updated directly without any modifications
    // Therefore, we are using one method with a 'key' to access the proper case in the switch
    // the dispatch method is also the same for all of these
    const returnUpdate = (value, pick) => {
        if (dropShip) {
            switch (pick) {
                case "name":
                    dispatch(setDropShip(
                        {
                            ...dropShip,
                            ship_to_name: value
                        }
                    ))
                    break;
                case "street":
                    dispatch(setDropShip(
                        {
                            ...dropShip,
                            address: {
                                ...dropShip.address,
                                street: value
                            }
                        }
                    ))
                    break;
                case "route":
                    dispatch(setDropShip(
                        {
                            ...dropShip,
                            address: {
                                ...dropShip.address,
                                route: value
                            }
                        }
                    ))
                    break;
                case "state":
                    dispatch(setDropShip(
                        {
                            ...dropShip,
                            address: {
                                ...dropShip.address,
                                state: value
                            }
                        }
                    ))
                    break;
                case "city":
                    dispatch(setDropShip(
                        {
                            ...dropShip,
                            address: {
                                ...dropShip.address,
                                city: value
                            }
                        }
                    ))
                    break;
                case "zip":
                    dispatch(setDropShip(
                        {
                            ...dropShip,
                            address: {
                                ...dropShip.address,
                                zip_code: value
                            }
                        }
                    ))
                    break;
            }
        } else {
            // Im not sure this condition is ever used, because we are just updating the return address fields either from the drop ship or the order customer address so it should always be populated
            const customer = creditOrder.customer
            dispatch(setDropShip(
                {
                    ship_to_name: pick === "name" ? value : customer.name,
                    address: {
                        street: pick === "street" ? value : customer.shipping_address.street,
                        route: pick === "route" ? value : customer.shipping_address.route,
                        city: pick === "state" ? value : customer.shipping_address.route,
                        state: pick === "city" ? value : customer.shipping_address.route,
                        zip_code: pick === "zip" ? value : customer.shipping_address.route
                    }
                }
            ))
        }
    }


    return (
        <>
            {/* 
            All of this information is provided initially by the order that is being credited. Most of this does not need to be changed ever with the exception of the return address
            For trouble shooting, if something looks weird check the order to make sure it is not also weird on the order
        */}
            {show &&
                <Card>
                    <Card.Title className="bottom-border-seperator">
                        {creditOrder.customer.name} ({creditOrder.customer.customer_num})
                    </Card.Title>


                    <Row>
                        <Col>
                            <Card.Title className="mb-0">
                                Contact Information
                            </Card.Title>
                            <Card.Body className="mt-0">
                                <b>{creditOrder.customer.contact.name}</b>
                                <br /><b>Phone:</b> {creditOrder.customer.contact.phone}
                                <br /><b>Email:</b> {creditOrder.customer.contact.emails[0]}
                            </Card.Body>
                        </Col>

                        <Col >
                            <Card.Title className="mb-0">
                                Original Order Information
                            </Card.Title>
                            <Card.Body className="mt-0">
                                <b>Invoice Number: </b> {creditOrder.customer_invoice_num}
                                <br /><b>Order Number: </b>{creditOrder.customer_order_num}
                                <br /><b>Customer PO: </b>{creditOrder.customer_po}

                            </Card.Body>
                        </Col>
                    </Row>
                    <Row className="mt-5">

                        <Col>
                            <Card.Title className="mb-0">
                                Billing Address
                            </Card.Title>
                            <Card.Body className="mt-0">
                                {creditOrder.customer.billing_address.street}
                                <br />{creditOrder.customer.billing_address.route}
                                <br />{creditOrder.customer.billing_address.city + " " + creditOrder.customer.billing_address.state + " " + creditOrder.customer.billing_address.zip_code}
                            </Card.Body>
                        </Col>
                        <Col>
                            <Card.Title className="mb-0">
                                Shipping Address
                            </Card.Title>
                            <Card.Body className="mt-0">
                                {creditOrder.customer.shipping_address.street.trim().length > 1 ? creditOrder.customer.shipping_address.street.trim() + "\n" : ""}
                                {creditOrder.customer.shipping_address.route}
                                <br />{creditOrder.customer.shipping_address.city + " " + creditOrder.customer.shipping_address.state + " " + creditOrder.customer.shipping_address.zip_code}
                            </Card.Body>
                        </Col>
                        {creditOrder.freight.is_drop_ship && <Col>
                            <Card.Title className="mb-0">
                                Drop Ship Address
                            </Card.Title>
                            <Card.Body className="mt-0">
                                {creditOrder.freight.drop_ship_address.address.street.trim().length > 1 ? creditOrder.freight.drop_ship_address.address.street.trim() + "\n" : ""}
                                {creditOrder.freight.drop_ship_address.address.route}
                                <br />{creditOrder.freight.drop_ship_address.address.city + " " + creditOrder.freight.drop_ship_address.address.state + " " + creditOrder.freight.drop_ship_address.address.zip_code}
                            </Card.Body>
                        </Col>}
                    </Row>
                    {/* 
                        These return information fields populate initially as follows:

                        IF there is a drop ship address on the original order THEN populate using that
                        ELSE populate using the customer shipping address

                        When this information is changed it is always saved to the dropship regardless of drop ship being present or not

                        These fields are also populated on rebill or adjustment credits even though they will never need it. 
                        If this ever becomes a complaint we can always not display this information if it is not standard
                    */}
                    <div className="cust-return">
                        <Card.Title className="mt-5 bottom-border-seperator">Return Information </Card.Title>
                        <Row>
                            <Col className="cust-return-name">
                                <div className="info-header">Customer Name</div>
                                <TextField
                                    className="w-25"
                                    disabled={creditType !== STANDARD_CREDIT.id || disabled}
                                    value={dropShip ? dropShip.ship_to_name : creditOrder.customer.name}
                                    onChange={(e) => returnUpdate(e.target.value, "name")}
                                ></TextField>
                            </Col>
                        </Row>
                        <Row className="mt-5 cust-return">
                            <Col>
                                <div className="info-header">Return Street</div>
                                <TextField
                                    className="w-100"
                                    disabled={creditType !== STANDARD_CREDIT.id || disabled}
                                    value={dropShip ? dropShip.address.street : creditOrder.customer.shipping_address.street}
                                    onChange={(e) => returnUpdate(e.target.value, "street")}
                                ></TextField>
                            </Col>
                            <Col>
                                <div className="info-header">Return Route</div>
                                <TextField
                                    className="w-100"
                                    disabled={creditType !== STANDARD_CREDIT.id || disabled}
                                    value={dropShip ? dropShip.address.route : creditOrder.customer.shipping_address.route}
                                    onChange={(e) => returnUpdate(e.target.value, "route")}
                                ></TextField>
                            </Col>
                            <Col>
                                <div className="info-header">Return City</div>
                                <TextField
                                    className="w-50"
                                    disabled={creditType !== STANDARD_CREDIT.id || disabled}
                                    value={dropShip ? dropShip.address.city : creditOrder.customer.shipping_address.city}
                                    onChange={(e) => returnUpdate(e.target.value, "city")}
                                ></TextField>
                            </Col>
                            <Col>
                                <div className="info-header">Return State</div>
                                <TextField
                                    className="w-50"
                                    disabled={creditType !== STANDARD_CREDIT.id || disabled}
                                    value={dropShip ? dropShip.address.state : creditOrder.customer.shipping_address.state}
                                    onChange={(e) => returnUpdate(e.target.value.slice(0, 2), "state")}
                                ></TextField>
                            </Col>
                            <Col>
                                <div className="info-header">Return Zip</div>
                                <TextField
                                    className="w-50"
                                    disabled={creditType !== STANDARD_CREDIT.id || disabled}
                                    value={dropShip ? dropShip.address.zip_code : creditOrder.customer.shipping_address.zip_code}
                                    onChange={(e) => returnUpdate(e.target.value, "zip")}
                                ></TextField>
                            </Col>
                        </Row>
                    </div>
                </Card>}
        </>
    )
}

export default CreditInputCustomer;