import { memo } from "react";
import { Modal, Button, Row, Col, Card, Dropdown } from "react-bootstrap";
import "./DuplicateCreditPopup.scss";
import {
  CREDIT_APPROVAL_STATUS,
  CREDIT_HOLD_STATUS,
  PENDING,
} from "../../../helpers/const";

/**
 * This is definitely the most complicated popup used in the credit application,
 * it actually defines two components, but the status selector is private
 *
 * The main modal shows a summary of the credit and the status selector
 *
 * @param content used to display a summary of what is going to be saved
 * @param show determines whether or not the modal should be hidden
 * @param handleCancelEvent what to do when the user clicks cancel
 * @param handleConfirmEvent what to do when the user click the confirm button
 * @param status the status of the credit (choices of CREDIT_APPROVAL_STATUS), this is an object NOT a number it has the field {id, label}
 * @param holdStatus the holdstatus of the credit (choices of CREDIT_HOLD_STATUS), this is an object NOT a number it has the field {id, label}
 * @param setHoldStatus setter for holdStatus to keep the status updated accross components
 * @param setStatus setter for status to keep the status updated accross components
 *
 * @returns
 */
function ConfirmSaveCreditPopup({
  content,
  show,
  handleCancelEvent,
  handleConfirmEvent,
  status,
  holdStatus,
  setHoldStatus,
  setStatus,
}) {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>Credit Summary</b>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Card>
          <Card.Title className="mb-4">Save Credit?</Card.Title>
          <Row>
            <Col>
              <b>Cust #:</b> {content.customer.customer_num}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Order #:</b> {content.customer_order_num}
            </Col>
            <Col>
              <b>Invoice #:</b> {content.customer_invoice_num}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Customer PO:</b> {content.customer_po}
            </Col>
            <Col>
              <b>Date Entered: </b> {content.date_of_order}
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <Card.Title className="mb-4">Select the Status</Card.Title>
            </Col>

            <Card.Body>
              <StatusSelector
                status={status}
                holdStatus={holdStatus}
                setHoldStatus={setHoldStatus}
                setStatus={setStatus}
              />
            </Card.Body>
          </Row>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Save Credit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const StatusSelector = ({ status, holdStatus, setHoldStatus, setStatus }) => {
  return (
    <>
      <table className="subh">
        <div className="d-flex">
          <tr className="wraps">
            <Dropdown>
              <Dropdown.Toggle className="drop-status-button">
                {status.label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {CREDIT_APPROVAL_STATUS.map((status, index) => (
                  <Dropdown.Item
                    eventKey={index}
                    className="dropdown-draft"
                    onClick={(e) => setStatus(status)}
                  >
                    {" "}
                    {status.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </tr>
          {status.id === PENDING.id && (
            <tr className="ms-2">
              <Dropdown>
                <Dropdown.Toggle className="drop-status-button">
                  {holdStatus.label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {CREDIT_HOLD_STATUS.map((status, index) => (
                    <Dropdown.Item
                      key={status.id}
                      eventKey={index}
                      onClick={(e) => setHoldStatus(status)}
                      className="dropdown-draft"
                    >
                      {" "}
                      {status.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </tr>
          )}
        </div>
      </table>
    </>
  );
};

export default memo(ConfirmSaveCreditPopup);
