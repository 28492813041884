import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { endpoints } from "../api/endpoints";
import Claims from "../views/Claims/Claims";
import NotFound from "../views/NotFound";
import { Alert } from "@mui/material";
import CreditEntry from "../views/Credits/CreditEntry";
import CreditSearch from "../views/Credits/CreditSearch";

function CustomerCredits(props) {
    const CREDIT_ENTRY = endpoints.CREDIT_ENTRY
    return (
        <>
            {props.groups.includes(props.creditGroup) ? <Container className="">
                <Routes>
                    {/* <Route path={""} element={<Claims />} /> */}
                    <Route path={""} element={<CreditEntry />}/>
                    <Route
                        path={"/credit/:id"}
                        element={<CreditEntry />}
                    />
                    <Route path={"/credit-search"} element={<CreditSearch />} />
                </Routes>
            </Container> :
                <Container>
                {props.groups.length > 0 &&<span>
                    <Alert severity="error" variant="outlined" className="mt-5">
                        You are not allowed to view this page. If you believe you are seeing this in error, please contact a system administrator
                    </Alert>
                    <a href="/">Back to home</a>
                    </span>}
                </Container>
            }
        </>
    )
}

export default CustomerCredits;