import axios from "axios";
import { endpoints } from "./endpoints";
import { makeQueryParams } from "../helpers/helperFunctions";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "x-csrftoken";

export const apiDelete = {
  lineItem: deleteLineItem,
  deleteSubOrder,
  resetBol,
  deleteCredit
};

function deleteLineItem(lineItemId, customerOrderNum) {
  return () =>
    axios.delete(
      endpoints.MITCO_API_URL +
        "/v1" +
        endpoints.SUB_ORDER_READ +
        customerOrderNum +
        endpoints.SUBORDER_ITEMS_DELETE +
        lineItemId +
        "/",
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      },
    );
}

async function deleteSubOrder(id) {
  const response = await axios.delete(
    endpoints.MITCO_API_URL + "/v1" + endpoints.DELETE_SUB_ORDER + id,
    {
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    },
  );
  return response;
}

async function resetBol({ customerOrderNum, subOrderIds = [] }) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.BOL}${customerOrderNum}/reset/`;

  const response = await axios.delete(url, {
    withCredentials: true,
    headers: { Accept: "application/json" },
    data: {
      suborders_ids: subOrderIds,
    },
  });

  return response;
}

async function deleteCredit(credit_memo_num) {
  const url = `${endpoints.MITCO_API_URL}/v1${endpoints.CREDIT_BASE + credit_memo_num + "/"}`;
  const response = await axios.delete(url, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    responseType: 'json',
    params: {
      credit_memo_num
    }

    
  })
  return response;
}