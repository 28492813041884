import { memo, useState } from "react";
import { Modal, Button, Row, Col, Card } from "react-bootstrap";
import "./DuplicateCreditPopup.scss"
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import { Grid } from "@mui/material";

function DuplicateCreditPopup({
  content,
  show,
  handleCancelEvent,
  handleConfirmEvent,
}) {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>Order Summary</div>
      </Modal.Header>

      <Modal.Body>

        <Card>
            <Card.Title className="mb-4">
                Are you sure you want to continue?
            </Card.Title>
            <Row>
            <Col>
                    <b>Cust #:</b> {content.customer.customer_num}
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>Order #:</b> {content.customer_order_num}
                </Col>
                <Col>
                    <b>Invoice #:</b> {content.customer_invoice_num}
                </Col>
                
            </Row>
            <Row>
                <Col>
                    <b>Customer PO:</b> {content.customer_po}
                </Col>
                <Col>
                    <b>Date Entered: </b> {content.date_of_order}
                </Col>
            </Row>      
        </Card>

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={"danger"}
          onClick={handleCancelEvent}
        >
          Cancel
        </Button>
        <Button
          variant={"primary"}
          onClick={handleConfirmEvent}
        >
          Start Credit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(DuplicateCreditPopup);
