import { memo, useState } from "react";
import { Modal, Button, Row, Col, Card, Dropdown } from "react-bootstrap";
import "./DuplicateCreditPopup.scss"
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import { APPRAISAL, CREDIT_APPROVAL_STATUS, CREDIT_HOLD_STATUS, CREDIT_STATUS, OPEN_ORDER_STATUS, ORDER_HOLD_STATUS, ORDER_STATUS, PENDING, PENDING_ORDER_STATUS, RETURN } from "../../../helpers/const";

function SendConfirmationPopup({
  show,
  handleCancelEvent,
  handleConfirmEvent,
  status,
  holdStatus,
}) {
  const [currentMethod, setCurrentMethod] = useState("print")
  const [email, setCurrentEmail] = useState("")
  const [useRga, setUseRga] = useState(true)
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div><b>Send Confirmation{(PENDING.id === parseInt(status) && parseInt(holdStatus) === RETURN.id) ? " and RGA" : ""}</b></div>
        {(PENDING.id === parseInt(status) && parseInt(holdStatus) === RETURN.id) && <FormGroup className="mt-3">
          <FormControlLabel
            id="useRga"
            label="Send With RGA"
            control={<Checkbox
              defaultChecked={true}
              onClick={(e) => setUseRga(e.target.checked)}
            />}
          />
        </FormGroup>}
      </Modal.Header>

      <Modal.Body>

        <Card>
          <Card.Title className="mb-4">
            <Row>
              <Col style={{ display: "grid", alignItems: "center" }}>
                <div >
                  Delivery Method
                </div>
              </Col>
              <Col>
                <Dropdown className="f-right" >
                  <Dropdown.Toggle
                    className="drop-status-button f-right"

                  >
                    {currentMethod}
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    <Dropdown.Item
                      onClick={(e) => setCurrentMethod(e.target.textContent.toLowerCase())}
                    >
                      Print
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => setCurrentMethod(e.target.textContent.toLowerCase())}
                    >
                      Email
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Card.Title>
          <Card.Body>
            {currentMethod === "email" &&
              <Row>
                <Col style={{ display: "grid", alignItems: "center" }}>
                  Email Address:
                </Col>
                <Col style={{ display: "grid", alignItems: "center" }}>
                  <TextField
                    value={email}
                    onChange={(e) => setCurrentEmail(e.target.value)}
                    inputProps={{
                      maxLength: 40
                    }}
                  />
                </Col>
              </Row>
            }
          </Card.Body>
        </Card>

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={"danger"}
          onClick={handleCancelEvent}
        >
          Cancel
        </Button>
        <Button
          variant={"primary"}
          onClick={() => handleConfirmEvent(currentMethod, email, useRga, true)}
        >
          {currentMethod === "email" ? "Send" : "Print"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(SendConfirmationPopup);
