import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";
import { setSelectedRate } from "../Credits/CreditSlice";
import { apiPost } from "../../../api/apiPost";
import { FREIGHT_LINE_PACKAGE_RATER_MAPPING, PREPAID_OPTIONS } from "../../../helpers/const";

export const rateLTL = createAsyncThunk(
    "rater/rateLTL",
    async ({ fromZip, warehouseZip, weight }, thunkAPI) => {
        try {
            const czarZip = fromZip;
            const destinationZip = warehouseZip;


            const response = await apiGet.rateLTLOptions(
                czarZip,
                destinationZip,
                weight
              )

            return response
        } catch (err) {
            return thunkAPI.rejectWithValue({
                axiosError: err,
                customMsg: "Error rating the shipment.",
            });
        }
    }
);

export const getPackageRaterOptions = createAsyncThunk(
  "freightRater/package",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        credits: {
            creditOrder,
            selectedWarehouse
        },
        freightRater: {
            packageOptions: packages
        }
      } = storeStates;

      const fromZip = creditOrder.customer.shipping_address.zip_code;
      const toZip = selectedWarehouse.zip;

      const fromState = await apiGet.getStateFromZipCode(fromZip);
      const toState = await apiGet.getStateFromZipCode(toZip);

      if (!fromState) {
        return thunkAPI.rejectWithValue({
          customMsg: "Invalid Czar zip code.",
        });
      }

      if (!toState) {
        return thunkAPI.rejectWithValue({
          customMsg:
            "Unable to use package rater with this destination zip code.",
        });
      }

      if (!Object.values(packages).find((item) => +item.weight)) {
        return thunkAPI.rejectWithValue({
          customMsg: "At least one package should have a weight.",
        });
      }

      let upsServices = [];
      let fedexServices = [];
      let upsStatus = true;
      let fedexStatus = true;

      try {
        const resultUPS = await apiPost.createUPSPackageQuote(
          fromState,
          fromZip,
          packages,
          toState,
          toZip,
        );

        const resultUPSJson = JSON.parse(resultUPS)?.RatedServices?.Service;

        // upsService might be array or object. So we should check out it is array to use 'map' function.
        upsServices = Array.isArray(resultUPSJson)
          ? resultUPSJson
          : [resultUPSJson];
      } catch (err) {
        upsStatus = false;
        console.log(err);
      }

      try {
        const resultFedEx = await apiPost.createFedExPackageQuote(
          fromState,
          fromZip,
          packages,
          toState,
          toZip,
        );

        const resultFedExJson = JSON.parse(resultFedEx)?.RatedServices?.Service;

        // fedexService might be array or object. So we should check out it is array to use 'map' function.
        fedexServices = Array.isArray(resultFedExJson)
          ? resultFedExJson
          : [resultFedExJson];
      } catch (err) {
        fedexStatus = false;
        console.log(err);
      }

      const formattedUPSOptions = upsServices.map((option, index) => ({
        id: index + 1,
        postalService: "UPS",
        service: option.Description._text,
        rate: option.TotalCharges._text,
        days: option.BusinessDaysInTransit._text,
        goalDeliveryTarget: option.ScheduledDeliveryTime._text,
      }));

      const formattedFedexOptions = fedexServices.map((option, index) => ({
        id: formattedUPSOptions.length + index + 1,
        postalService: "FedEx",
        service: option.Description._text,
        rate: option.TotalCharges._text,
        days: option.BusinessDaysInTransit._text,
        goalDeliveryTarget: option.ScheduledDeliveryTime._text,
      }));

      const packageRaterAllOptions = [
        ...formattedUPSOptions,
        ...formattedFedexOptions,
      ];

      packageRaterAllOptions.sort(
        (a, b) => parseFloat(a.rate) - parseFloat(b.rate),
      );

      return { packageRaterAllOptions, upsStatus, fedexStatus };
    } catch (err) {
      if (err?.response?.data?.detail) {
        return thunkAPI.rejectWithValue({
          axiosError: err,
          customMsg: err.response.data.detail,
        });
      }
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading package rater options.",
      });
    }
  },
);


export const FreightRaterSlice = createSlice({
    name: "FreightRaterSlice",
    initialState: {
        LTLOptions: [],
        packageOptions: [],
        packageResults: [],
        selectedRate: {},
        tempRate: undefined,
        modPrice: 0,
        rating: false,
        upsStatus: true,
        fedexStatus: true,
        prepaid: "Y"
    },
    reducers: {
        resetRater: (state, action) => {
            state.LTLOptions = []
            state.packageOptions = []
            state.packageResults = []
            state.selectedRate = {}
            state.tempRate = undefined
            state.modPrice = 0
            state.rating = false
            state.upsStatus = true
            state.fedexStatus = true
        },
        setSelectedRateGeneric: (state, action) => {
            state.selectedRate = action.payload
            if (state.selectedRate.quote.service) {
              state.selectedRate.quote = {
                ...state.selectedRate.quote,
                id: FREIGHT_LINE_PACKAGE_RATER_MAPPING.filter(rate => rate.packageRaterName === state.selectedRate.quote.service)[0].id
              }
            }
        },
        setTempRate: (state, action) => {
            state.tempRate = action.payload
        },
        setModPrice: (state, action) => {
          state.modPrice = action.payload 
        },
        setPackageOptions: (state, action) => {

            let items = action.payload
            let packs = []
            for (let i = 0; i < items.length; i++) {
                let curr = items[i]
                let count = 0
                let pack = {
                    weight: 0,
                    dv: 0,
                    boxed: false
                }
                while (count < curr.return) {
                    pack = {
                        ...pack,
                        weight: parseFloat(pack.weight) + parseFloat(curr.weight),
                        dv: pack.dv + parseFloat(curr.item_price)
                    }
                    if (pack.weight > (150 - curr.weight)) {
                        packs.push(pack)
                        pack = {
                            ...pack,
                            weight: 0,
                            dv: 0
                        }
                    }
                    count += 1
                }
            }
            state.packageOptions = packs
        },
        addNewPackage: (state, action) => {
            let packs = state.packageOptions
            packs.push(
                {
                    weight: 0,
                    dv: 0,
                    boxed: false
                }
            )
            state.packageOptions = packs
        },
        removePackage: (state, action) => {
            let packs = state.packageOptions
            packs.splice(action.payload, 1)
            state.packageOptions = packs
        },
        updatePack: (state, action) => {
            let packs = state.packageOptions
            const index = action.payload.index
            switch (action.payload.toUpdate) {
                case "weight":
                    packs[index] = {
                        ...packs[index],
                        weight: action.payload.value
                    }
                    break;
                case "dv":
                    packs[index] = {
                        ...packs[index],
                        dv: action.payload.value
                    }
                    break;
                case "boxed":
                    
                    packs[index] = {
                        ...packs[index],
                        boxed: !packs[index].boxed
                    }
                    break;
            }

            state.packageOptions = packs
        },
        setPrepaid: (state, action) => {
          state.prepaid = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(rateLTL.pending, (state, action) => {
                state.rating = true
            })
            .addCase(rateLTL.fulfilled, (state, action) => {
                state.rating = false
                state.LTLOptions = action.payload
            })
            .addCase(rateLTL.rejected, (state, action) => {
                state.rating = false
            })
            .addCase(getPackageRaterOptions.pending, (state, action) => {
                state.rating = true
            })
            .addCase(getPackageRaterOptions.fulfilled, (state, action) => {
                state.rating = false
                state.packageResults = action.payload.packageRaterAllOptions
                state.upsStatus = action.payload.upsStatus
                state.fedexStatus = action.payload.fedexStatus
            })
            .addCase(getPackageRaterOptions.rejected, (state, action) => {
                state.rating = false
            })
    }
})

export const {
    setSelectedRateGeneric,
    setTempRate,
    setModPrice,
    setPackageOptions,
    addNewPackage,
    removePackage,
    updatePack,
    resetRater,
    setPrepaid
} = FreightRaterSlice.actions

export default FreightRaterSlice.reducer