import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Col, Row } from "react-bootstrap";

const FreightRaterSelection = (props) => {

    return (
        <Row >
            <Col     >
                <FormGroup className="mt-3">
                    <FormControlLabel
                        id="rate"
                        label="Rate LTL"
                        control={<Checkbox
                            value={props.selectedRater}
                            checked={(props.selectedRater === "LTL")}
                            onClick={() => props.setSelectedRater("LTL")}
                        />}
                    />
                </FormGroup>
            </Col>
            <Col >
                <FormGroup className="mt-3">
                    <FormControlLabel
                        id="rate"
                        label="Rate Package"
                        control={<Checkbox
                            value={props.selectedRater}
                            checked={(props.selectedRater === "PACKAGE")}
                            onClick={() => props.setSelectedRater("PACKAGE")}
                        />}
                    />
                </FormGroup>
            </Col>
        </Row>
    )

}

export default FreightRaterSelection;