import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import Table from "../../../components/atoms/Table/Table";
import { AlertMessageContext } from "../../../components/atoms/AlertMessage/AlertMessage";
import {
  setModPrice,
  setPrepaid,
  setSelectedRateGeneric,
  setTempRate,
} from "../../../store/slices/FreightRater/FreightRaterSlice";
import { PREPAID_OPTIONS } from "../../../helpers/const";
import { setFreightMin } from "../../../store/slices/Credits/CreditSlice";

const FreightRaterLTL = (props) => {
  const dispatch = useDispatch();

  const alertNotification = useContext(AlertMessageContext);

  const originalFreight = parseFloat(props.order.freight.freight_amount);

  const { selectedRate, tempRate, modPrice, LTLOptions, prepaid } = useSelector(
    (state) => state.freightRater
  );
  const { freightMinimum } = useSelector((state) => state.credits);

  const [prepaidChanged, setPrepaidChanged] = useState(false);
  const [minFreight, setMinFreight] = useState(
    freightMinimum !== 0 ? freightMinimum : originalFreight * -1
  );

  useEffect(() => {
    if (tempRate) {
      const min =
        originalFreight * -1 +
        (tempRate && tempRate.rate ? parseFloat(tempRate.rate) : 0) * -2;
      setMinFreight(min);
      dispatch(setFreightMin(min));
    }
  }, [tempRate]);

  const handleSelect = () => {
    if (
      !selectedRate ||
      selectedRate.quote !== tempRate.TMSQuoteNumber ||
      modPrice !== selectedRate.customPrice ||
      prepaidChanged
    ) {
      dispatch(
        setSelectedRateGeneric({ quote: tempRate, customPrice: modPrice })
      );
      setPrepaidChanged(false);
      alertNotification.handleOpen(
        "success-savign-as-sub-order",
        "success",
        "Freight updated succesfully!",
        2000
      );
    } else {
      alertNotification.handleOpen(
        "success-savign-as-sub-order",
        "warning",
        "Freight did not change, please make a different selection to perform this action.",
        2000
      );
    }
  };

  const handleSelectedFreight = useCallback((id, data) => {
    dispatch(setTempRate(data));
    dispatch(setModPrice(-1 * data.net));
  }, []);

  const handleFreightChange = (val) => {
    let value = val;

    if (value > originalFreight) {
      value = originalFreight;
    } else if (value < minFreight) {
      value = minFreight;
    }

    dispatch(setModPrice(value));
  };

  return (
    <>
      {LTLOptions && LTLOptions.length > 0 && (
        <div>
          <div className="drop-ship-popup__table mt-5">
            <Table
              selector="radio"
              headerAttributes={[
                {
                  id: "id",
                  label: "Select",
                },
                {
                  id: "name",
                  label: "Name",
                },
                {
                  id: "net",
                  label: "Net",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "days",
                  label: "Days",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "carrier",
                  label: "Carrier",
                },
                {
                  id: "carrierQuoteNumber",
                  label: "CarrierQuoteNumber",
                },
                {
                  id: "TMSQuoteNumber",
                  label: "TMSQuoteNumber",
                },
              ]}
              dataList={LTLOptions}
              hideId={true}
              selectedItem={tempRate?.id}
              onSelectRow={handleSelectedFreight}
            />
          </div>
          <Row>
            <Col>
              <FormControlLabel
                value={PREPAID_OPTIONS.PREPAID}
                control={
                  <Checkbox
                    checked={prepaid === "Y"}
                    onChange={(e) => {
                      dispatch(setPrepaid(prepaid === "Y" ? "N" : "Y"));
                      setPrepaidChanged(true);
                    }}
                  />
                }
                label="Customer Pay Freight"
              />
            </Col>
            <Col className="ml-5">
              <div className="info-header">Freight Amount</div>
              <TextField
                value={modPrice}
                helperText={`Min: ${
                  minFreight ? minFreight.toFixed(2) : 0
                } Max: ${originalFreight} `}
                onChange={(e) => handleFreightChange(e.target.value)}
                type="number"
                inputProps={{
                  maxLength: 7,
                }}
              ></TextField>
            </Col>

            <Col className="d-flex justify-content-end">
              <Button
                disable={!tempRate}
                variant="primary"
                className="mx-3 "
                style={{ maxHeight: 48, marginTop: "2%" }}
                onClick={handleSelect}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default FreightRaterLTL;
