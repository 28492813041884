import { Card, Col, Row } from "react-bootstrap";
import { CREDIT_REASON, STANDARD_CREDIT } from "../../../helpers/const";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  setSelectedWarehouse,
  updateCredit,
} from "../../../store/slices/Credits/CreditSlice";
import { setModPrice } from "../../../store/slices/FreightRater/FreightRaterSlice";

/**
 *
 * @param show boolean that is toggled in parent to display or hide this component
 * @param disabled boolean defined in parent that determines if the order is editable or not
 * @param creditType type of CREDIT_TYPE use for conditional rendering to simplify UI
 *
 * @returns
 */
const CreditInputFields = ({ show, disabled, creditType }) => {
  const {
    creditOrder,
    activeWarehouses,
    credit: savedCredit,
    selectedWarehouse,
    freightMinimum,
  } = useSelector((state) => state.credits);
  const { modPrice } = useSelector((state) => state.freightRater);
  const dispatch = useDispatch();

  const originalFreight = parseFloat(creditOrder.freight.freight_amount);
  const [minFreight, setMinFreight] = useState(
    freightMinimum !== 0 ? freightMinimum : originalFreight * -1
  );

  const [creditCard, setCreditCard] = useState(0.0);
  const [surcharge, setSurcharge] = useState(0.0);
  const [tax, setTax] = useState(0.0);

  //This object is used initially with these 5 fields, but if we are looking at a saved credit, it also contains all of the credit fields and some of the names are different
  const [credit, setCredit] = useState(
    savedCredit
      ? savedCredit
      : {
          creditReason: 0,
          creditcardCharge: 0.0,
          surcharge: 0.0,
          handling_charge: 0.0,
          tax: 0.0,
        }
  );

  //whenever credit changes, we need to check if it is a new credit or we have loaded a credit
  // credit.freight_line only exists in a loaded credit
  // if we do have a loaded credit, make sure the handling is set correctly
  useEffect(() => {
    if (credit.freight_line && credit.freight_line.handling_charge) {
      setCredit({
        ...credit,
        handling_charge: credit.freight_line.handling_charge,
      });
    }
    dispatch(updateCredit(credit));
  }, [credit]);

  // Possible credit reasons are defined in CONST.js
  const updateCreditReason = (value) => {
    const reason = CREDIT_REASON.filter((reason) => reason.label === value)[0];
    setCredit({
      ...credit,
      creditReason: reason.id,
    });
  };

  const updateWarehouse = (value) => {
    dispatch(setSelectedWarehouse(parseInt(value)));
  };

  const updateHandling = (value) => {
    let val = value;
    if (
      parseFloat(value) >
      parseFloat(creditOrder.freight.freight_line.handling_charge)
    ) {
      val = creditOrder.freight.freight_line.handling_charge;
    }
    setCredit({
      ...credit,
      handling_charge: val,
    });
  };

  const updateFreightCredit = (val) => {
    let value = val;

    if (value > creditOrder.freight.freight_amount) {
      value = creditOrder.freight.freight_amount;
    } else if (value < minFreight) {
      value = minFreight;
    }
    dispatch(setModPrice(value));

    if (savedCredit) {
      setCredit({
        ...credit,
        freight_credit: value,
      });
    } else {
      setCredit({
        ...credit,
        freightCredit: value,
      });
    }
  };

  const updateCreditCardCharge = (value) => {
    let val = value;
    if (parseFloat(val) > parseFloat(creditOrder.creditcard_charge)) {
      val = creditOrder.creditcard_charge;
    }
    setCreditCard(val);
    setCredit({
      ...credit,
      creditcardCharge: val,
    });
  };

  const updateSurcharge = (value) => {
    let val = value;
    if (val > creditOrder.surcharge) {
      val = creditOrder.surcharge;
    }
    setSurcharge(val);

    setCredit({
      ...credit,
      surcharge: val,
    });
  };

  const updateTax = (value) => {
    let val = value;
    if (val > creditOrder.tax) {
      val = creditOrder.tax;
    }
    setTax(val);
    setCredit({
      ...credit,
      tax: val,
    });
  };

  useEffect(() => {
    setMinFreight(freightMinimum);
  }, [freightMinimum]);

  const creditReasonProps = {
    options: Object.values(CREDIT_REASON),
    getOptionLabel: (option) => option.label,
  };

  const warehouseProps = {
    options: activeWarehouses,
    getOptionLabel: (option) => option.warehouse_id + " - " + option.name,
  };

  return (
    <>
      <Card hidden={!show}>
        <Card.Body>
          <Row className="cust-return">
            <Col>
              <div className="info-header">Credit Reason</div>
              <Autocomplete
                disableClearable
                disabled={disabled}
                {...creditReasonProps}
                className="autocomplete-input-credit"
                value={
                  CREDIT_REASON.filter(
                    (reason) =>
                      reason.id ===
                      (credit.creditReason
                        ? credit.creditReason
                        : parseInt(credit.credit_reason))
                  )[0]
                    ? CREDIT_REASON.filter(
                        (reason) =>
                          reason.id ===
                          (credit.creditReason
                            ? credit.creditReason
                            : parseInt(credit.credit_reason))
                      )[0]
                    : CREDIT_REASON.filter((reason) => reason.id === 0)[0]
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e) => updateCreditReason(e.target.textContent)}
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
            <Col>
              <div className="info-header">Warehouse</div>
              <Autocomplete
                disableClearable
                disabled={disabled}
                {...warehouseProps}
                className="autocomplete-input-credit"
                value={selectedWarehouse ? selectedWarehouse : 0}
                onChange={(e) =>
                  updateWarehouse(e.target.textContent.slice(0, 1))
                }
                isOptionEqualToValue={(option, value) =>
                  option.warehouse_id === value.warehouse_id
                }
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
            <Col>
              <div className="info-header">Freight Credit</div>
              <TextField
                disabled={disabled}
                value={modPrice}
                onChange={(e) => updateFreightCredit(e.target.value)}
                type="number"
                helperText={
                  "Min: " + minFreight.toFixed(2) + " Max: " + originalFreight
                }
                inputProps={{
                  maxLength: 6,
                }}
              ></TextField>
            </Col>

            <Col>
              <div className="info-header">Handling Charge</div>
              <TextField
                disabled={disabled}
                value={credit.handling_charge}
                onChange={(e) =>
                  updateHandling(e.target.value.replace(/[^0-9.-]/g, ""))
                }
                inputProps={{
                  maxLength: 6,
                }}
                helperText={
                  "Maximum Allowed: " +
                  creditOrder.freight.freight_line.handling_charge
                }
              ></TextField>
            </Col>
            <Col>
              <div className="info-header">Credit Card Charge</div>
              <TextField
                disabled={disabled}
                value={
                  savedCredit && savedCredit.creditcard_charge
                    ? savedCredit.creditcard_charge
                    : credit.creditcardCharge
                }
                inputProps={{
                  maxLength: 6,
                }}
                onChange={(e) =>
                  updateCreditCardCharge(
                    e.target.value.replace(/[^0-9.-]/g, "")
                  )
                }
                helperText={"Maximum Allowed: " + creditOrder.creditcard_charge}
              ></TextField>
            </Col>
            <Col>
              <div className="info-header">Surcharge</div>
              <TextField
                disabled={disabled}
                value={
                  savedCredit && savedCredit.surcharge
                    ? savedCredit.surcharge
                    : credit.surcharge
                }
                inputProps={{
                  maxLength: 6,
                }}
                onChange={(e) =>
                  updateSurcharge(e.target.value.replace(/[^0-9.-]/g, ""))
                }
                helperText={"Maximum Allowed: " + creditOrder.surcharge}
              ></TextField>
            </Col>
            <Col>
              <div className="info-header">Tax</div>
              <TextField
                disabled={disabled}
                value={
                  savedCredit && savedCredit.tax ? savedCredit.tax : credit.tax
                }
                inputProps={{
                  maxLength: 6,
                }}
                onChange={(e) =>
                  updateTax(e.target.value.replace(/[^0-9.-]/g, ""))
                }
                helperText={"Maximum Allowed: " + creditOrder.tax}
              ></TextField>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreditInputFields;
