import { BASE_API_URL } from "../api/baseUrl";

export const KEY_CODES = {
  ENTER: 13
}

export const CREDENTIAL_ISSUE = "Incorrect Credentials";
export const ERR_NETWORK =
  "Error connecting with API. Make sure the VPN is connected and that your browser trusts this url " +
  BASE_API_URL;
export const SERVER_FORBIDEN_ERROR =
  "Forbidden: You don't have permission to access on this server";
export const SERVER_GENERAL_SERVER_ERRROR =
  "The server encountered an unexpected condition that prevented it from fulfilling the request";
export const EMAIL_REGEX_EXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

export const ORDER_ITEM_NONE_TYPE = -1;
export const ORDER_ITEM_INVENTORY_TYPE = 0;
export const ORDER_ITEM_MOUNT_TYPE = 1;
export const ORDER_ITEM_DESCRIPTION_TYPE = 4;
export const ORDER_ITEM_ADDON_TYPE = 5;
export const ORDER_ITEM_XREF_TYPE = 6;

export const OPEN_ORDER_STATUS = {
  id: -1,
  label: "Open",
};

export const PENDING_ORDER_STATUS = {
  id: 0,
  label: "Pending",
};

export const APPROVED_ORDER_STATUS = {
  id: 1,
  label: "Approved",
};

export const IN_PROGRESS_ORDER_STATUS = {
  id: 2,
  label: "In Progress",
};

export const COMPLETED_ORDER_STATUS = {
  id: 3,
  label: "Completed",
};

export const INVOICED_ORDER_STATUS = {
  id: 4,
  label: "Invoiced",
};

export const POSTED_ORDER_STATUS = {
  id: 5,
  label: "Posted",
};

export const ORDER_STATUS = [
  OPEN_ORDER_STATUS,
  PENDING_ORDER_STATUS,
  APPROVED_ORDER_STATUS,
  IN_PROGRESS_ORDER_STATUS,
  COMPLETED_ORDER_STATUS,
  INVOICED_ORDER_STATUS,
  POSTED_ORDER_STATUS,
];

export const PREPAID_ORDER_HOLD_STATUS = {
  id: 0,
  label: "Prepaid",
};

export const PICK_UP_ORDER_HOLD_STATUS = {
  id: 1,
  label: "Pick up",
};

export const DATE_ORDER_HOLD_STATUS = {
  id: 2,
  label: "Date",
};

export const BACKORDER_ORDER_HOLD_STATUS = {
  id: 3,
  label: "Backorder",
};

export const MANUFACTURE_ORDER_HOLD_STATUS = {
  id: 4,
  label: "Manufacture",
};

export const CREDIT_ORDER_HOLD_STATUS = {
  id: 5,
  label: "Credit",
};

export const INFORMATION_ORDER_HOLD_STATUS = {
  id: 6,
  label: "Information",
};

export const DIRECT_SHIP_ORDER_HOLD_STATUS = {
  id: 7,
  label: "Direct Ship",
};

export const EDIT_ORDER_HOLD_STATUS = {
  id: 8,
  label: "Edit",
};

export const CREDIT_HOLD_ORDER_HOLD_STATUS = {
  id: 9,
  label: "Credit hold",
};

export const PRICE_ORDER_HOLD_STATUS = {
  id: 10,
  label: "Price",
};

export const TRANSFER_ORDER_HOLD_STATUS = {
  id: 11,
  label: "Transfer",
};

export const RESEARCH_ORDER_HOLD_STATUS = {
  id: 12,
  label: "Research",
};

export const SHIP_DUE_ORDER_HOLD_STATUS = {
  id: 13,
  label: "Ship Date Due",
};

export const ORDER_HOLD_STATUS = [
  PREPAID_ORDER_HOLD_STATUS,
  PICK_UP_ORDER_HOLD_STATUS,
  DATE_ORDER_HOLD_STATUS,
  BACKORDER_ORDER_HOLD_STATUS,
  MANUFACTURE_ORDER_HOLD_STATUS,
  CREDIT_ORDER_HOLD_STATUS,
  INFORMATION_ORDER_HOLD_STATUS,
  DIRECT_SHIP_ORDER_HOLD_STATUS,
  EDIT_ORDER_HOLD_STATUS,
  CREDIT_HOLD_ORDER_HOLD_STATUS,
  PRICE_ORDER_HOLD_STATUS,
  TRANSFER_ORDER_HOLD_STATUS,
  RESEARCH_ORDER_HOLD_STATUS,
  SHIP_DUE_ORDER_HOLD_STATUS,
];

export const AR_BALANCE_NONE_STATUS = "";
export const AR_BALANCE_GOOD_STATUS = "good";
export const AR_BALANCE_MEDIUM_STATUS = "medium";
export const AR_BALANCE_BAD_STATUS = "bad";

export const CREDIT_STATUS = {
  0: {
    value: "Ok",
    message: "Credit OK",
    status: AR_BALANCE_GOOD_STATUS,
  },
  1: {
    value: "Approved",
    message: "Credit Already Approved",
    status: AR_BALANCE_GOOD_STATUS,
  },
  2: {
    value: "Req Approval",
    message: "Order terms requires credit approval",
    status: AR_BALANCE_MEDIUM_STATUS,
  },
  3: {
    value: "Limit Exceeded",
    message: "Credit Limit Exceeded",
    status: AR_BALANCE_BAD_STATUS,
  },
  4: {
    value: "Overdue",
    message: "Past due invoices present",
    status: AR_BALANCE_BAD_STATUS,
  },
};

export const CUSTOMER_ID_REF = "customerId";
export const SUB_ORDER_PO = "subOrderPo";
export const SUB_ORDER_BOL = "subOrderBol";
export const CUSTOMER_SECTION_REF = "customerSection";
export const DETAILS_SECTION_REF = "detailsSection";
export const ITEMS_SECTION_REF = "itemsSection";
export const FREIGHT_SECTION_REF = "freightSection";

export const PREPAID_WEIGHT = 1000;

export const INTIAL_PACKAGE_RATER_HANLDING = 5;

export const FREIGHT_LINE_PACKAGE_RATER_MAPPING = [
  { id: 36, name: "UPS SECOND DAY AIR", packageRaterName: "UPS 2nd Day Air" },
  { id: 26, name: "UPS STD GROUND", packageRaterName: "UPS Ground" },
  { id: 27, name: "UPS 3 DAY SELECT", packageRaterName: "UPS 3 Day Select" },
  { id: 33, name: "UPS NEXT DAY AIR", packageRaterName: "UPS Next Day Air" },
  {
    id: 33,
    name: "UPS NEXT DAY AIR",
    packageRaterName: "UPS Next Day Air Saver",
  },
  {
    id: 33,
    name: "UPS NEXT DAY AIR",
    packageRaterName: "UPS Next Day Air Early",
  },
  {
    id: 36,
    name: "UPS SECOND DAY AIR",
    packageRaterName: "UPS 2nd Day Air A.M.",
  },
  { id: 71, name: "FEDEX GROUND", packageRaterName: "FEDEX_GROUND" },
  {
    id: 16,
    name: "FEDEX AIR FRT 1 DAY",
    packageRaterName: "FEDEX_1_DAY_FREIGHT",
  },
  {
    id: 65,
    name: "FEDEX AIR FRT 2 DAY",
    packageRaterName: "FEDEX_2_DAY_FREIGHT",
  },
  {
    id: 17,
    name: "FEDEX AIR FRT 3 DAY",
    packageRaterName: "FEDEX_3_DAY_FREIGHT",
  },
  {
    id: 117,
    name: "FEDEX FIRST OVERNIGHT",
    packageRaterName: "FEDEX_FIRST_OVERNIGHT",
  },
  {
    id: 117,
    name: "FEDEX FIRST OVERNIGHT",
    packageRaterName: "FEDEX_FIRST_OVERNIGHT_FREIGHT",
  },
  {
    id: 12,
    name: "FEDEX PRIORITY OVERNIGHT",
    packageRaterName: "FEDEX_PRIORITY_OVERNIGHT",
  },
  {
    id: 76,
    name: "FEDEX STANDARD OVERNIGHT",
    packageRaterName: "FEDEX_STANDARD_OVERNIGHT",
  },
];

export const INITIAL_PACKAGE = {
  items: [],
  weight: 0,
  dv: 0,
  boxed: false,
};

export const MIN_WEIGHT_CAN_BE_COMBINED = 10;
export const MAX_WEIGHT_OF_COMBINED_PACKAGE = 75;
export const MAX_WEIGHT_OF_BOXED_PACKAGE = 50;
export const MAX_WEIGHT_OF_SHIPPING_ITEM = 150;

export const PREPAID_OPTIONS = {
  PREPAID: "prepaid",
  PREPAID_AND_ADD: "prepaidAndAdd",
  COLLECT: "collect",
};

export const STANDARD_CREDIT = {
  id: 0,
  label: "Standard"
}

export const ADJUSTMENT_CREDIT = {
  id: 1,
  label: "Adjustment"
}

export const REBILL_CREDIT = {
  id: 2,
  label: "Rebill"
}

export const CREDIT_TYPE = [
  STANDARD_CREDIT,
  ADJUSTMENT_CREDIT,
  REBILL_CREDIT,
];

export const PENDING = {
  id: 0,
  label: "Pending"
}

export const APPROVED = {
  id: 1,
  label: "Approved"
}

export const IN_PROGRESS = {
  id: 2,
  label: "In Progress"
}

export const COMPLETED = {
  id: 3,
  label: "Completed"
}

export const INVOICED = {
  id: 4,
  label: "Invoiced"
}

export const POSTED = {
  id: 5,
  label: "Posted"
}

export const CLOSED = {
  id: 6,
  label: "Closed"
}

export const APPRAISAL = {
  id: 0,
  label: "Appraisal"
}

export const APPROVAL = {
  id: 1,
  label: "Approval"
}

export const DATE = {
  id: 2,
  label: "Date"
}

export const RETURN = {
  id: 3,
  label: "Return"
}



export const CREDIT_APPROVAL_STATUS = [
  PENDING ,
    APPROVED,
    IN_PROGRESS,
    COMPLETED,
    INVOICED,
    POSTED,
    CLOSED
]

export const CREDIT_HOLD_STATUS = [
  APPRAISAL,
    APPROVAL,
    DATE,
    RETURN
]

export const NOT_ASSIGNED = {
  id: 0,
  label: "Not Assigned"
}

export const SUPPLIER_ERROR = {
  id: 1,
  label: "Supplier Error"
}

export const ORDER_ENTRY_ERROR = {
  id: 2,
  label: "Order Entry Error"
}

export const WAREHOUSE_ERROR = {
  id: 3,
  label: "Warehouse Error"
}

export const CUSTOMER_ERROR = {
  id: 4,
  label: "Customer Error"
}

export const FREIGHT_LINE_ERROR = {
  id: 5,
  label: "Freight Line Error"
}

export const DISCOUNT_TAKEN = {
  id: 6,
  label: "Discount Taken"
}

export const NAT_ACCT_CREDIT = {
  id: 7,
  label: "National Account Credit"
}

export const RATER_TYPE = {
  CREDIT_RATER: 0,
  ORDER_RATER: 1
}


export const CREDIT_REASON = [
  NOT_ASSIGNED,
    SUPPLIER_ERROR,
    ORDER_ENTRY_ERROR,
    WAREHOUSE_ERROR,
    CUSTOMER_ERROR,
    FREIGHT_LINE_ERROR,
    DISCOUNT_TAKEN,
    NAT_ACCT_CREDIT
]

export const CREDIT_LINE_TYPE = {
  NORMAL:0,
  MOUNTIONG_CHARGE:1,
  NON_SALE:2,
  SPECIALTY:3,
  DESCRIPTION:4
}