import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeImagesByKey, setCreditImages, updateImageRedux } from "../../../store/slices/Credits/CreditSlice";
import ImageSlider from "./ImageSlider";

/**
 * 
 * @param disabled determines whether the user should be allowed to add or remove images.  The user should still be able to scroll images though
 * @param itemKey used in combination with sequence to order and identify each image
 * @param sequence used in combination with itemKey to order and identify each image
 * 
 * @returns 
 */
const ImageUpload = ({
    disabled,
    itemKey,
    sequence
}) => {
    //This is an abitrary amount defined by the end user, there is no reason it can not be changed. 
    // If it is changed the rest of the program will reflect this change.
    const IMAGE_MAX = 6

    //This is where all images and accessed from
    const { creditImages } = useSelector(state => state.credits)

    const [imageCount, setImageCount] = useState(0)
    const [showSlideShow, setShowSlideShow] = useState(false)

    const dispatch = useDispatch();

    //Images will be saved as null until we upload an image, null images are screened out before saving
    const incImageCount = () => {
        setImageCount(imageCount + 1)
        if (imageCount + 1 <= IMAGE_MAX) {
            dispatch(
                setCreditImages([
                    ...creditImages,
                    {
                        id: itemKey + "_" + sequence + "_" + Math.floor(Math.random() * 1000),
                        key: itemKey,
                        sequence: sequence,
                        image: null,
                    }
                ])
            )
        }

    }

    //This also decrements the image count
    const handleImageDelete = (id) => {
        dispatch(removeImagesByKey({ id }))
        setImageCount(imageCount - 1)
    }

    const updateImage = (id, file) => {
        dispatch(updateImageRedux({ id, file: URL.createObjectURL(file) }))
    }


    return (
        <>

            <Card>
                <Card.Header className="bottom-border-seperator pb-2">
                    <Row >
                        <Col className="mobile-btn">
                        {/* This checks if there is at least one image loaded, ithen updates slide show accordingly*/}
                            {creditImages.filter(
                                (image) => image.key === itemKey && image.sequence === sequence && image.image !== null)
                                .length > 0 &&
                                <div className="show-image-button" onClick={() => setShowSlideShow(!showSlideShow)}>
                                    {showSlideShow ? "Hide Images" : "View Images"}
                                </div>}
                        </Col>
                        <Col >
                            <Button
                                className="f-right m-100"
                                onClick={() => incImageCount()}
                                disabled={((imageCount) >= IMAGE_MAX) || disabled}
                            >
                                Add Image
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col className="image-parent">
                            {showSlideShow && 
                            // Slideshow to display iamges
                            <ImageSlider
                                setShowSlideShow={setShowSlideShow}
                                deleteAction={handleImageDelete}
                                images={creditImages.filter((image) => image.key === itemKey && image.sequence === sequence && image.image !== null)}
                            />}
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            {/* This code only manages null images, once it is no longer null, the Image slider takes over */}
                            {creditImages.filter((image) => image.key === itemKey && image.sequence === sequence).map(
                                (image, index) => (
                                    <Row key={index}>
                                        <Col
                                            key={parseInt(index)}
                                            className="mt-2"
                                        >
                                            {image.image === null &&
                                                <input type="file" name="someImage" onChange={(e) => updateImage(image.id, e.target.files[0])} />
                                            }
                                        </Col>
                                    </Row>
                                )
                            )
                            }
                        </Col>
                    </Row>
                </Card.Body>

            </Card>
        </>
    )
}

export default ImageUpload;