import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./CreditInput.scss";
import { AiOutlineSave } from "react-icons/ai";
import { useState } from "react";
import WarningRestartPopup from "../../../components/atoms/CreditPopups/WarningRestartPopup";
import ConfirmSaveCreditPopup from "../../../components/atoms/CreditPopups/ConfirmSaveCreditPopup";
import WarningDeletePopup from "../../../components/atoms/CreditPopups/WarningDeletePopup";
import {
  CREDIT_APPROVAL_STATUS,
  CREDIT_HOLD_STATUS,
  REBILL_CREDIT,
  STANDARD_CREDIT,
} from "../../../helpers/const";

/**
 * Props are destructured to help with readability
 *
 * @param {} creditMemoNum this parameter is undefined when we are creating a new credit
 * @param disabled true under certain conditions defined in CreditEntry
 * @param resetFunction called to send the user back to enter another invoice number
 * @param saveFunction this function is used to save an credit regardless of the credit being an update or an initial save
 * @param status the status of the function, can be anything in CREDIT_APPROVAL_STATUS const
 * @param holdStatus this is default 0 unless status === PENDING then it can be anything in CREDIT_HOLD_STATUS const
 * @param setStatus function used to update status in the parent, this is defined in parent because it is needed to perform saving properly
 * @param setHoldStatus function used to update hold status in the parent, this is defined in parent because it is needed to perform saving properly
 * @param handleSave this functions logic is located in CreditEntry, the popup needed to be abstracted to keep the parent clean thats why it is needed here
 * @param showSaveCreditPopup this function simply toggles the save popup boolean to true or false
 * @param deleteFunction parent provided delete function used to delete a credit and ask for confirmation
 * @param setShowSendConfirmationPopup toggles the display variable to show the confirmation popup
 *
 * @returns
 */
const CreditInputHeader = ({
  creditMemoNum,
  disabled,
  resetFunction,
  saveFunction,
  status,
  holdStatus,
  setStatus,
  setHoldStatus,
  handleSave,
  showSaveCreditPopup,
  deleteFunction,
  setShowSendConfirmationPopup,
  creditType,
}) => {
  const [showRestartPopup, setShowRestartPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { creditOrder, creditTypeString, credit, creditItems } = useSelector(
    (state) => state.credits
  );
  const { selectedRate } = useSelector((state) => state.freightRater);

  return (
    <>
      <div className="invis-bg mt-5">
        <Row>
          <Col>
            {/* The credit type always shows, but other than that the type */}
            <h4>{creditTypeString}</h4>
            {creditMemoNum && (
              <h6>Credit Memo Number: {credit.credit_memo_num}</h6>
            )}
            {creditMemoNum && (
              <h6>Credit Item(s) Total: {credit.total_credit}</h6>
            )}
            {creditMemoNum && <h6>Memo Total: {credit.memo_amount}</h6>}
            {creditMemoNum && (
              <div>
                <b>Status:</b>{" "}
                {
                  CREDIT_APPROVAL_STATUS.filter(
                    (status) => status.id === parseInt(credit.status)
                  )[0].label
                }{" "}
                {parseInt(credit.status) === 0
                  ? CREDIT_HOLD_STATUS.filter(
                      (status) => status.id === parseInt(credit.hold_status)
                    )[0].label
                  : ""}
              </div>
            )}
          </Col>
          <Col className="space-around mobile-vert">
            <div>
              <Dropdown>
                <Dropdown.Toggle className="drop-status-button">
                  Tools
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowRestartPopup(true)}>
                    Restart
                  </Dropdown.Item>
                  {creditMemoNum && (
                    <Dropdown.Item
                      onClick={() => setShowSendConfirmationPopup(true)}
                    >
                      Send Confirmation
                    </Dropdown.Item>
                  )}
                  {creditMemoNum && (
                    <Dropdown.Item
                      className="del-tool"
                      // This is very important this disabled is here, if it was not, the user would be able to delete a posted credit
                      disabled={disabled}
                      onClick={() => setShowDeletePopup(true)}
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              {/* 
                            This button is disabled when the disabled bool (defined in CreditEntry) is true 
                            or if the user has not rated the credit
                            or if the user has no items
                            */}
              <Button
                disabled={
                  disabled ||
                  creditItems.length <= 0 ||
                  (!selectedRate.quote && creditType === STANDARD_CREDIT.id)
                }
                onClick={() => saveFunction(true)}
                className="f-right"
              >
                Save <AiOutlineSave />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {/* Popup that warns the user they are about to lose all progress and restart */}
      <WarningRestartPopup
        handleConfirmEvent={resetFunction}
        handleCancelEvent={() => setShowRestartPopup(false)}
        show={showRestartPopup}
      />
      {/* Warning that tells the user they are about to delete a credit */}
      <WarningDeletePopup
        handleConfirmEvent={() => {
          deleteFunction();
          setShowDeletePopup(false);
        }}
        handleCancelEvent={() => setShowDeletePopup(false)}
        show={showDeletePopup}
      />
      {/* Warning that tells the user they are about to save and asks for the status */}
      {creditOrder && (
        <ConfirmSaveCreditPopup
          show={showSaveCreditPopup}
          content={creditOrder}
          handleCancelEvent={() => handleSave(false)}
          handleConfirmEvent={handleSave}
          status={status}
          holdStatus={holdStatus}
          setStatus={setStatus}
          setHoldStatus={setHoldStatus}
        />
      )}
    </>
  );
};

export default CreditInputHeader;
