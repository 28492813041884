import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";
import { apiPost } from "../../../api/apiPost";
import { apiDelete } from "../../../api/apiDelete";
import {
  CREDIT_LINE_TYPE,
  CREDIT_TYPE,
  ORDER_ITEM_ADDON_TYPE,
} from "../../../helpers/const";

export const getInitialOrder = createAsyncThunk(
  "credit/initialize",
  async ({ invoiceNum }, thunkAPI) => {
    try {
      const response = await apiGet.orderByInvoice(invoiceNum);
      if (response.data.Error) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading the initial order (Does it exist?).",
      });
    }
  }
);

export const checkDuplicateCredit = createAsyncThunk(
  "credit/duplicate",
  async ({ invoiceNum }, thunkAPI) => {
    try {
      const response = await apiGet.duplicateCredit(invoiceNum);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const getProblemList = createAsyncThunk(
  "credit/problems",
  async (thunkAPI) => {
    try {
      const response = await apiGet.problemList();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const getActiveWarehouses = createAsyncThunk(
  "credit/warehouses",
  async (thunkAPI) => {
    try {
      const response = await apiGet.activeWarehouses();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const deleteCredit = createAsyncThunk(
  "credit/deleteCredit",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        credits: { credit },
      } = storeStates;

      const response = await apiDelete.deleteCredit(credit.credit_memo_num);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const rateLTLCredit = createAsyncThunk(
  "credit/rateLTL",
  async ({ fromZip, warehouseZip, weight }, thunkAPI) => {
    try {
      const czarZip = fromZip;
      const destinationZip = warehouseZip;

      const response = await apiGet.rateLTLOptions(
        czarZip,
        destinationZip,
        weight
      );

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error rating the credit.",
      });
    }
  }
);

export const createCredit = createAsyncThunk(
  "credit/create",
  async ({ status, holdStatus }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        credits: {
          creditType: type,
          credit,
          creditOrder,
          dropShip,
          selectedWarehouse,
          creditItems,
          creditImages,
          loadedCredit,
        },
        freightRater: { selectedRate, modPrice, prepaid },
      } = storeStates;

      const response = await apiPost.createCredit({
        credit_memo_num: loadedCredit ? loadedCredit.credit_memo_num : 0,
        type,
        holdStatus,
        status,
        creditReason: credit.creditReason
          ? credit.creditReason
          : credit.credit_reason,
        invoiceNum: creditOrder.customer_invoice_num,
        freightCredit: credit.freight_credit
          ? credit.freight_credit
          : credit.freightCredit,
        freightAmount: modPrice,
        dropShip,
        prepaid,
        directShip: "N",
        selectedWarehouse,
        creditCardCharge: credit.creditCardCharge
          ? credit.creditCardCharge
          : credit.creditCard_charge,
        surcharge: credit.surcharge,
        tax: credit.tax,
        creditItems,
        selectedRate,
        customer: creditOrder.customer,
      });

      const credit_memo_num = response.data.credit_memo_num;
      const keys = response.data.key_transformations;

      //handle images

      let tempImages = [...creditImages];
      let resultImages = [];
      let sequences = [];
      tempImages = tempImages.filter((image) => image.image !== null);
      for (let i = 0; i < tempImages.length; i++) {
        let curr_image = tempImages[i];
        let curr_key = keys.filter(
          (key) => key.old_key === curr_image.sequence
        )[0];
        if (!curr_key) {
          continue;
        }
        curr_image = {
          ...curr_image,
          sequence: curr_key.new_key,
        };
        const response = await fetch(curr_image.image);

        sequences.push(`${curr_image.key}_${curr_image.sequence}`);
        const blob = await response.blob();
        let tempImg = new File([blob], credit_memo_num + ".JPEG", {
          type: blob.type,
        });
        resultImages.push(tempImg);
      }

      const image_response = await apiPost.uploadImages({
        images: resultImages,
        credit_memo_num,
        sequences,
      });

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error saving the credit.",
      });
    }
  }
);

export const getCredit = createAsyncThunk(
  "credit/get",
  async ({ id }, thunkAPI) => {
    try {
      const response = await apiGet.getCredit(id);

      const credit = response.data;

      const order = await apiGet.orderByInvoice(credit.ref_invoice);

      return {
        credit: credit,
        order: order.data,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the credit.",
      });
    }
  }
);

export const getImages = createAsyncThunk(
  "credit/getImages",
  async ({ id, item, inc }, thunkAPI) => {
    try {
      let count = 1;
      let result = [];
      const pc = item.product_code;
      while (true) {
        const image = await apiGet.getImage({ id, pc, inc, count });
        if (image.result || count === 6) {
          break;
        } else {
          result.push({
            id: id + "_" + pc + "_" + inc + "_" + count,
            key: pc,
            sequence: inc,
            image: URL.createObjectURL(image.data),
          });
          count += 1;
        }
      }

      return {
        images: result,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the credit.",
      });
    }
  }
);

export const doConfirmationAction = createAsyncThunk(
  "credit/doConfirmationAction",
  async ({ email, method, credit_memo_num, use_rga }, thunkAPI) => {
    try {
      const response = await apiGet.doConfirmationAction({
        email,
        method,
        credit_memo_num,
        use_rga,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error sending the confirmation.",
      });
    }
  }
);

export const getCreditsByFilter = createAsyncThunk(
  "credit/creditsByFilter",
  async (
    {
      credit_memo_num,
      ref_invoice,
      return_address,
      to,
      from,
      status,
      selected,
      page,
      order_by,
      sort_order,
    },
    thunkAPI
  ) => {
    try {
      const response = await apiGet.getCredits({
        credit_memo_num,
        ref_invoice,
        return_address,
        to,
        from,
        status,
        selected,
        page,
        order_by,
        sort_order,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading the credits.",
      });
    }
  }
);

export const creditSlice = createSlice({
  name: "creditSlice",
  initialState: {
    credit: undefined,
    creditLoading: false,
    creditTypeString: "Standard Credit",
    creditType: 0,
    displayItems: [],
    creditOrder: undefined,
    problemList: [],
    creditItems: [],
    activeWarehouses: [],
    selectedWarehouse: {
      warehouse_id: 0,
      name: "",
      zip: 0,
    },
    totalWeight: 0,
    freightAmount: 0,
    dropShip: undefined,
    loadedCredit: undefined,
    creditImages: [],
    freightMinimum: 0,
  },
  reducers: {
    resetRedux: (state, action) => {
      state.displayItems = [];
      state.creditOrder = undefined;
      state.problemList = [];
      state.creditItems = [];
      state.freightAmount = 0;
      state.selectedRate = undefined;
      state.selectedWarehouse = undefined;
      state.totalWeight = 0;
      state.ratedOptions = [];
      state.displayItems = [];
    },
    setFreightMin: (state, action) => {
      state.freightMinimum = action.payload;
    },
    updateCredit: (state, action) => {
      state.credit = action.payload;
    },
    setCreditLoading: (state, action) => {
      state.creditLoading = action.payload;
    },
    setCreditTypeString: (state, action) => {
      state.creditType = parseInt(action.payload);
      state.creditTypeString = CREDIT_TYPE.filter(
        (type) => type.id === state.creditType
      )[0].label;
    },
    addCreditItem: (state, action) => {
      //action is a object => defined in LineItem.js
      state.creditItems.push(action.payload);
    },
    removeCreditItem: (state, action) => {
      state.creditItems = state.creditItems.filter(
        (item) => item.key !== action.payload
      );
    },
    addDescriptionItem: (state, action) => {
      let items = state.displayItems.slice(0);
      let pay = action.payload;
      let displayU = 1;
      let found = false;
      let result = [];
      let random = Math.random();

      for (let i = 0; i < items.length; i++) {
        let item = {
          description: "",
          type: 4, //this will not change
          parentId: pay.line_num,
          unique: i + 1,
          sequence_num: random,
        };
        displayU = i + 1;

        if (item.parentId === items[i].line_num) {
          for (let j = i + 1; j < items.length; j++) {
            if (items[j].line_num) {
              item = {
                ...item,
                unique: displayU,
              };
              items.splice(j, 0, item);

              found = true;
              break;
            }
          }
          if (!found) {
            items.push({
              ...item,
              unique: displayU,
            });
          }
          break;
        }
      }

      state.displayItems = [...items];
      state.creditItems = [...items];
    },
    removeDescriptionItem: (state, action) => {
      const key = action.payload.key;
      const filtered_display_item = state.displayItems.filter(
        (item) => item.sequence_num === key
      )[0];
      const displayIndex = state.displayItems.indexOf(filtered_display_item);
      state.displayItems.splice(displayIndex, 1);

      const filtered_credit_item = state.creditItems.filter(
        (item) => item.sequence_num === key
      )[0];
      const creditIndex = state.creditItems.indexOf(filtered_credit_item);
      state.creditItems.splice(creditIndex, 1);
    },
    updateDescriptionItem: (state, action) => {
      const key = action.payload.key;
      const description = action.payload.description;
      const filtered_display_item = state.displayItems.filter(
        (item) => item.sequence_num === key
      )[0];
      const displayIndex = state.displayItems.indexOf(filtered_display_item);
      state.displayItems[displayIndex].description = description;

      const filtered_credit_item = state.creditItems.filter(
        (item) => item.sequence_num === key
      )[0];

      const creditIndex = state.creditItems.indexOf(filtered_credit_item);
      state.creditItems[creditIndex].description = description;
    },
    setSelectedWarehouse: (state, action) => {
      const warehouse = state.activeWarehouses.filter((warehouse) => {
        return warehouse.warehouse_id === action.payload;
      });

      state.selectedWarehouse = warehouse[0];
    },
    setTotalWeight: (state, action) => {
      state.totalWeight = action.payload;
    },
    setDropShip: (state, action) => {
      state.dropShip = action.payload;
    },
    updateImageRedux: (state, action) => {
      const id = action.payload.id;
      const file = action.payload.file;
      const filtered_image = state.creditImages.filter(
        (image) => image.id === id
      )[0];
      const index = state.creditImages.indexOf(filtered_image);
      state.creditImages[index].image = file;
    },
    setCreditImages: (state, action) => {
      state.creditImages = action.payload;
    },
    removeImagesByKey: (state, action) => {
      const id = action.payload.id;
      if (id) {
        state.creditImages = state.creditImages.filter(
          (image) => image.id !== id
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCredit.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(createCredit.fulfilled, (state, action) => {
        state.creditLoading = false;
        state.credit.creditMemoNum = action.payload.credit_memo_num;
      })
      .addCase(createCredit.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(getCredit.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(getCredit.fulfilled, (state, action) => {
        state.creditLoading = false;
        state.creditItems = [];
        state.displayItems = [];
        //load the original order again
        for (let i = 0; i < action.payload.order.line_items.length; i++) {
          state.displayItems.push(action.payload.order.line_items[i].item);
        }
        state.creditOrder = action.payload.order;
        if (state.creditOrder.freight.is_drop_ship) {
          state.dropShip = state.creditOrder.freight.drop_ship_address;
        } else {
          state.dropShip = undefined;
        }

        //populate the credit fields
        const credit = action.payload.credit;
        state.loadedCredit = credit;
        state.freightAmount = credit.freight_amount;
        state.freightMinimum =
          state.creditOrder.freight.freight_amount * -1 +
          state.freightAmount * -2;
        state.credit = {
          creditReason: parseInt(credit.credit_reason),
          freightCredit: parseFloat(credit.freight_credit),
          creditcardCharge: parseFloat(credit.creditcard_charge),
          surcharge: parseFloat(credit.surcharge),
          tax: parseFloat(credit.tax),
        };

        state.creditType = parseInt(credit.credit_type);
        if (parseInt(state.creditType) === 0) {
          state.creditTypeString = "Standard Credit";
        }
        if (parseInt(state.creditType) === 1) {
          state.creditTypeString = "Adjustment Credit";
        }
        if (parseInt(state.creditType) === 2) {
          state.creditTypeString = "Rebill Credit";
        }

        state.selectedWarehouse = {
          warehouse_id: parseInt(credit.warehouse.warehouse_id),
          name: credit.warehouse.long_name,
          zip: credit.warehouse.czar_zip,
        };

        state.dropShip = {
          ship_to_name: credit.return_customer_name,
          address: {
            street: credit.return_street,
            route: credit.return_route,
            city: credit.return_city,
            state: credit.return_state,
            zip_code: credit.return_zip,
          },
        };

        const credit_items = state.loadedCredit.line_items;
        let last_line = 0;
        for (let i = 0; i < credit_items.length; i++) {
          let curr_credit = credit_items[i].item;
          const sub_items = credit_items[i].subitems
            ? credit_items[i].subitems
            : [];
          if (
            parseInt(curr_credit.item_type) !== CREDIT_LINE_TYPE.DESCRIPTION &&
            curr_credit.item_type !== ORDER_ITEM_ADDON_TYPE
          ) {
            let curr_order = state.displayItems
              .filter(
                (item) =>
                  item.product &&
                  item.product.product_code === curr_credit.product_code
              )
              .pop();
            if (!curr_order) {
              curr_order = state.displayItems.filter(
                (item) => item.product.product_code === curr_credit.product_code
              );
            }
            let item = {
              key:
                curr_order.product.product_code +
                `-${parseInt(curr_credit.sequence_num)}`,
              line_num: parseInt(curr_order.line_num)
                ? parseInt(curr_order.line_num)
                : parseInt(curr_order.sequence_num), // This should not change
              percent_allowed: parseFloat(curr_credit.percent_allowed),
              comments: curr_credit.comments,
              problem_num: parseInt(curr_credit.problem_num),
              type: parseInt(curr_credit.item_type), // This should not change
              return: parseInt(curr_credit.qty_shipped),
              credit_amount: parseFloat(curr_credit.credit_amount),
              manufacturer_defect: curr_credit.manufacturer_defect,
              added_to_stock: curr_credit.added_to_stock,
              item_price: parseInt(curr_credit.item_price),
              in_house: curr_credit.in_house,
              parentId: -1, //this never changes
              unique: -1, // this never changes
              weight: curr_credit.unit_weight,
              saved: true,
              serial_num: curr_credit.serial_num,
              sequence_num: curr_credit.sequence_num,
              product_code: curr_credit.product_code,
            };
            last_line = parseInt(curr_credit.sequence_num);
            state.creditItems = [...state.creditItems, item];
            for (let j = 0; j < sub_items.length; j++) {
              let credit_desc = sub_items[j];
              let item_desc = {
                description: credit_desc.description,
                type: 4, //this will not change
                parentId: item.line_num,
                unique: credit_desc.sequence_num,
                sequence_num: credit_desc.sequence_num,
              };

              state.creditItems.push(item_desc);
              let f = false;
              for (
                let i = state.displayItems.indexOf(curr_order) + 1;
                i < state.displayItems.length;
                i++
              ) {
                if (state.displayItems[i].product) {
                  state.displayItems.splice(i, 0, item_desc);
                  f = true;
                  break;
                }
              }
              if (!f) {
                state.displayItems.push(item_desc);
              }
              last_line += 1;
            }
          }
        }

        state.credit = credit;
        state.totalWeight = 0;
        for (let i = 0; i < state.creditItems.length; i++) {
          const currItem = state.creditItems[i];
          state.totalWeight += currItem.weight * currItem.return;
        }
      })
      .addCase(getCredit.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(checkDuplicateCredit.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(checkDuplicateCredit.fulfilled, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(checkDuplicateCredit.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(deleteCredit.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(deleteCredit.fulfilled, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(deleteCredit.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(getInitialOrder.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(getInitialOrder.fulfilled, (state, action) => {
        state.creditLoading = false;
        for (let i = 0; i < action.payload.line_items.length; i++) {
          state.displayItems.push(action.payload.line_items[i].item);
        }
        state.creditOrder = action.payload;
        if (state.creditOrder.freight.is_drop_ship) {
          state.dropShip = state.creditOrder.freight.drop_ship_address;
        } else {
          state.dropShip = undefined;
        }
      })
      .addCase(getInitialOrder.rejected, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(getProblemList.fulfilled, (state, action) => {
        state.problemList = action.payload;
      })
      .addCase(getActiveWarehouses.fulfilled, (state, action) => {
        state.activeWarehouses = action.payload;

        state.selectedWarehouse = state.activeWarehouses[0];
      })
      .addCase(getImages.fulfilled, (state, action) => {
        const newImages = action.payload.images;
        let res = [...state.creditImages];
        for (let i = 0; i < newImages.length; i++) {
          const find = res.filter((image) => image.id === newImages[i].id);
          if (!(find.length > 0)) {
            res.push(newImages[i]);
          }
        }
        state.creditImages = [...res];
      })
      .addCase(doConfirmationAction.fulfilled, (state, action) => {
        state.creditLoading = false;
      })
      .addCase(doConfirmationAction.pending, (state, action) => {
        state.creditLoading = true;
      })
      .addCase(doConfirmationAction.rejected, (state, action) => {
        state.creditLoading = false;
      });
  },
});

export const {
  setCreditLoading,
  setCreditTypeString,
  addCreditItem,
  removeCreditItem,
  addDescriptionItem,
  removeDescriptionItem,
  updateDescriptionItem,
  resetRedux,
  updateCredit,
  setSelectedWarehouse,
  setTotalWeight,
  setDropShip,
  updateImageRedux,
  addUsedNum,
  findNextNum,
  removeImagesByKey,
  setCreditImages,
  setFreightMin,
} = creditSlice.actions;

export default creditSlice.reducer;
