import React, { useEffect, useContext, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Chip,
  Badge,
  Alert,
} from "@mui/material";
import Table from "../../atoms/Table/Table";
import { formStyle } from "../../../theming/formStyle";

import {
  setFreight,
  setFreightPackages,
  setSelectedPackageRaterOption,
  setFreightRater,
  toggleFreightRaterPopupState,
  getBolDetail,
} from "../../../store/slices/freight/freightSlice";

import { AlertMessageContext } from "../../atoms/AlertMessage/AlertMessage";

import {
  FREIGHT_LINE_PACKAGE_RATER_MAPPING,
  INITIAL_PACKAGE,
  MAX_WEIGHT_OF_BOXED_PACKAGE,
  PREPAID_OPTIONS,
} from "../../../helpers/const";

import { getDaysLabel } from "../../../helpers/helperFunctions";
import {
  mergeMountedWeight,
  packageItems,
} from "../../../helpers/packagingHelper";
import { setAutoSaveFocusOuttedTrue } from "../../../store/slices/eventHandlerSlice";
import { getAppendedSuborderItems } from "../../../store/slices/item/itemSlice";
import InputNumber from "../../atoms/InputNumber";
import "./FreightDetails.scss"

export default function PackageResults({
  focusOnDataTable,
  applyButtonRef,
  cZarZipInputRef,
  setFocusOnDataTable,
}) {
  const alertNotification = useContext(AlertMessageContext);

  const {
    subOrderId,
    subOrder: { status: subOrderStatus },
  } = useSelector((state) => state.subOrder);
  const {
    weight,
    prepaid,
    thirdPartyBilling,
    freightAmount: initialFreightAmount,
    handlingCharge: initialHandlingCharge,
    bolDetail: { subOrderIds: appendedSuborderIds },
    prepaidWeight,
    freightRater: {
      czarZip,
      destinationZip,
      testCzarZip,
      testDestinationZip,
      packages: freightPackages,
      packageRaterOptions,
      selectedPackageRaterOption,
      upsStatus,
      fedexStatus,
    },
  } = useSelector((state) => state.freight);
  const { items, appendedItems } = useSelector((state) => state.items);
  const [dataList, setDataList] = useState(packageRaterOptions);
  const [selectedFreightPackage, setSelectedFreightPackage] = useState(
    selectedPackageRaterOption?.id,
  );
  const [packages, setPackages] = useState(freightPackages);
  const [freightAmount, setFreightAmount] = useState(initialFreightAmount);
  const [handling, setHandling] = useState(initialHandlingCharge);
  const [addHandling, setAddHandling] = useState(true);
  const [totalShippingHandling, setTotalShippingHandling] = useState(0);
  const [localPrepaidOption, setLocalPrepaidOption] = useState(
    prepaid
      ? +initialFreightAmount
        ? PREPAID_OPTIONS.COLLECT
        : PREPAID_OPTIONS.PREPAID
      : PREPAID_OPTIONS.PREPAID_AND_ADD,
  );

  const {
    items: orderItems
  } = useSelector((state) => state.items)
  const [showTrackWarning, setShowTrackWarning] = useState("none")
  useEffect(() => {
    var found = false

    if (orderItems.length > 0) {
      for (var i = 0; i < orderItems.length; i++) {
        var item = orderItems[i]
        if (String(item.productCode).substring(0, 2) === "17" || String(item.productCode).substring(0, 2) === "16" || String(item.productCode).substring(0, 2) === "15") {
          found = true
        }
      }
    }
    if (found === true) setShowTrackWarning("");
    else setShowTrackWarning("none")

  }, [orderItems])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFreightPackages(packages));
  }, [packages, dispatch]);

  useEffect(() => {
    if (packageRaterOptions) {
      setDataList(packageRaterOptions);
    }
  }, [packageRaterOptions]);

  useEffect(() => {
    dispatch(
      setSelectedPackageRaterOption(
        dataList.find((item) => item.id === selectedFreightPackage),
      ),
    );
  }, [selectedFreightPackage, dispatch, dataList]);

  useEffect(() => {
    if (subOrderId > 0) {
      dispatch(getBolDetail(subOrderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOrderId]);

  useEffect(() => {
    if (appendedSuborderIds && appendedSuborderIds.length) {
      dispatch(
        getAppendedSuborderItems({
          subOrderIds: appendedSuborderIds,
          subOrderStatus,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appendedSuborderIds, subOrderStatus]);

  useEffect(() => {
    try {
      setPackages(
        packageItems(mergeMountedWeight([...items, ...appendedItems])),
      );
    } catch (error) {
      alertNotification.handleOpen(
        "error-merge-mounted-weight",
        "danger",
        error.message,
        5000,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, appendedItems]);

  /*
   * When the freight amount changes, the prepaid option is updated.
   * Need to check of this should be updated when the total shipping and handling changes
   */
  useEffect(() => {
    const _totalShippingAndHandling = addHandling
      ? (+freightAmount + +handling).toFixed(2)
      : (+freightAmount).toFixed(2);
    setTotalShippingHandling(_totalShippingAndHandling);
    handlePackageAmountProccess(_totalShippingAndHandling);
  }, [freightAmount, handling, addHandling]);

  useEffect(() => {
    if (selectedPackageRaterOption) {
      setFreightAmount(selectedPackageRaterOption?.rate)
    }
  }, [selectedPackageRaterOption])

  /**
   * When handling is 0, the add handling checkbox is unchecked.
   * When handling changes, the handling value is updated in the store.
   */
  useEffect(() => {
    if (handling == 0) {
      setAddHandling(false);
    }
    dispatch(setFreightRater({ packageRaterHandling: (+handling).toFixed(2) }));
  }, [handling, dispatch]);

  const addNewPackage = () => {
    setPackages((prevState) => [...prevState, { ...INITIAL_PACKAGE }]);
  };

  // Update pacakges when a package value changes
  const updatePackages = (index, key, value) => {
    setPackages((prevState) => {
      let newPackages = [...prevState];
      const newPackage = { ...newPackages[index], [key]: value };
      newPackages.splice(index, 1, {
        ...newPackage,
        boxed: value,
      });

      return newPackages;
    });
  };

  const deletePackage = (index) => {
    setPackages((prevState) => {
      let newPackages = [...prevState];
      newPackages.splice(index, 1);

      return newPackages;
    });
  };

  /**
   * set Freight Amount and prepaidOption
   */
  const handlePackageAmountProccess = (freightAmountValue) => {
    if (localPrepaidOption === PREPAID_OPTIONS.COLLECT) return;

    if (+freightAmountValue > 0) {
      setLocalPrepaidOption(PREPAID_OPTIONS.PREPAID_AND_ADD);
    } else {
      setLocalPrepaidOption(PREPAID_OPTIONS.PREPAID);
    }
  };

  const getFreightLineByPackageRaterService = (service) => {
    const bestMatchingItem = FREIGHT_LINE_PACKAGE_RATER_MAPPING.find(
      (item) => item.packageRaterName === service,
    );

    return {
      id: bestMatchingItem?.id,
      title: `${bestMatchingItem?.id} - ${bestMatchingItem?.name}`,
    };
  };

  const onSelectRow = useCallback((id) => {
    setSelectedFreightPackage(id);
    setFocusOnDataTable(true);
  }, []);

  /**
   * Function to apply the handling and freight amount
   */
  const applyPackageRaterData = (
    selectedPackageRaterOptionValue,
    prepaidValue,
    freightAmount,
    shippingRate,
    thirdPartyBillingName,
  ) => {
    if (selectedPackageRaterOptionValue) {
      let prepaidCode = "";
      let prepaidBool = false;

      if (
        prepaidValue === PREPAID_OPTIONS.PREPAID ||
        prepaidValue === PREPAID_OPTIONS.PREPAID_AND_ADD
      ) {
        prepaidCode = thirdPartyBilling.name && thirdPartyBillingName.name.trim().length > 0 ? "3" : "Y";
        prepaidBool = prepaidCode === "3" ? false : true;
      } else {
        prepaidCode = "N";
        prepaidBool = false;
      }

      const bestMatchingRaterOption = getFreightLineByPackageRaterService(
        selectedPackageRaterOption?.service,
      );

      if (bestMatchingRaterOption?.id) {
        dispatch(
          setFreight({
            freightAmount: freightAmount,
            prepaidCode,
            prepaid: prepaidBool,
            estimatedCost: shippingRate,
            handlingCharge: handling,
            freightLine: getFreightLineByPackageRaterService(
              selectedPackageRaterOption?.service,
            ),
          }),
        );

        dispatch(
          setFreightRater({
            // carrierQuoteNumber, // The API endpoint does not have these values yet
            // TMSQuoteNumber, // The API endpoint does not have these values yet
            rated: true,
            ratedWeight: weight,
            carrier: selectedPackageRaterOptionValue.service, // Or .postalService
            selectedPackageRaterOption: null,
          }),
        );

        if (subOrderId) {
          dispatch(setAutoSaveFocusOuttedTrue());
        }

        dispatch(toggleFreightRaterPopupState(false));
      } else {
        alertNotification.handleOpen(
          "error-unavailable-rater-option",
          "danger",
          "This service is not configured to use in MITCO's system. Please use another one.",
          5000,
        );
      }
    }
  };

  const onCancel = () => {
    dispatch(toggleFreightRaterPopupState(false));
  };

  return (
    <>
      <Row>
        <Col className="package-rater-options-popup__packages-div mt-5 pt-3">
          {packages.map((item, index) => (
            <Row key={index} className="mb-4">
              <Col md="2" className="m-auto mb-4">
                PKG {index}
              </Col>
              <Col md="3">
                <TextField
                  label="Weight"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  value={item.weight}
                  onChange={(e) =>
                    updatePackages(index, "weight", +e.target.value)
                  }
                  error={item.weight > 150}
                  helperText={item.weight > 150 ? "Cannot exceed 150" : ""}
                />
              </Col>
              <Col md="3">
                <TextField
                  label="D.V"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  value={item.dv}
                  onChange={(e) => updatePackages(index, "dv", +e.target.value)}
                />
              </Col>
              <Col md="2">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={formStyle.checkboxStyle}
                      onChange={(e) =>
                        updatePackages(index, "boxed", e.target.checked)
                      }
                    />
                  }
                  label="Boxed"
                />
              </Col>
              <Col md="2" className="mt-3">
                <button
                  type="button"
                  onClick={() => deletePackage(index)}
                  className="package-delete-icon"
                >
                  <AiFillDelete />
                </button>
              </Col>
            </Row>
          ))}
          <div className="d-grid gap-2">
            <Button
              variant="outline-primary"
              size="sm"
              className="add-new-package-button"
              onClick={addNewPackage}
            >
              Add New Package
            </Button>
          </div>
        </Col>

        <Col>
          <div className="d-flex gap-4 mt-5 mb-2">
            <Badge color={upsStatus ? "success" : "error"} variant="dot">
              <Chip label="UPS" variant="outlined" />
            </Badge>
            <Badge color={fedexStatus ? "success" : "error"} variant="dot">
              <Chip label="FedEx" variant="outlined" />
            </Badge>
          </div>
          <div className="package-rater-options-popup__table">
            <Table
              selector="radio"
              headerAttributes={[
                {
                  id: "select",
                  label: "Select",
                },
                {
                  id: "postalService",
                  label: "PS",
                },
                {
                  id: "service",
                  label: "Service",
                },
                {
                  id: "rate",
                  label: "Rate",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "days",
                  label: "Days",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "goalDeliveryTarget",
                  label: "G D T",
                },
              ]}
              dataList={dataList}
              hideId={true}
              onSelectRow={onSelectRow}
              selectedItem={selectedFreightPackage}
              getFocus={focusOnDataTable}
              nextFocusRef={applyButtonRef}
              prevFocusRef={cZarZipInputRef}
              setFocusOnDataTable={setFocusOnDataTable}
            />
          </div>
        </Col>

        {selectedFreightPackage && (
          <Col md="12">
            <Col className="mt-3">
                <Alert style={{ color: "darkred", display: showTrackWarning }} severity="error">There is a track present, please check freight amount.</Alert>
              </Col>
            <Row className="mt-4">
              <Col md="5">
                <FormControl style={{ width: "100%" }}>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    className="mt-1"
                    value={localPrepaidOption}
                    name="prepaidOption"
                    onChange={(e) => {
                      setLocalPrepaidOption(e.target.value);
                      if (e.target.value === PREPAID_OPTIONS.PREPAID || e.target.value === PREPAID_OPTIONS.COLLECT) {
                        setTotalShippingHandling(handling)
                      } else if (e.target.value === PREPAID_OPTIONS.PREPAID_AND_ADD) {
                        setTotalShippingHandling(parseFloat(handling) + parseFloat(freightAmount))
                      }
                    }}
                  >
                    <Row>
                      <Col>
                        <FormControlLabel
                          value={PREPAID_OPTIONS.PREPAID}
                          control={<Radio />}
                          label="Prepaid"
                        />
                      </Col>
                      <Col>Prepaid Weight: {prepaidWeight}</Col>
                    </Row>
                    <Row>
                      <Col >
                        <FormControlLabel
                          value={PREPAID_OPTIONS.PREPAID_AND_ADD}
                          control={<Radio />}
                          label="Prepaid and Add"
                        />
                      </Col>
                      <Col>
                        <InputNumber
                          className="mt-3"
                          label="Freight Amount"
                          style={formStyle.inputStyle}
                          InputLabelProps={formStyle.inputLabelProps}
                          value={freightAmount}
                          onChange={(e) => {
                            setFreightAmount(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>

                  </RadioGroup>
                </FormControl>
              </Col>

              <Col md="2" className="hand-rate">
                <Col>
                  <InputNumber
                    className="mt-4 hand-input"
                    label="Handling"
                    style={formStyle.inputStyle}
                    InputLabelProps={formStyle.inputLabelProps}
                    value={handling}
                    onChange={(e) => {
                      setHandling(e.target.value);
                      setAddHandling(+e.target.value > 0 ? true : false);
                    }}
                  />
                </Col>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={formStyle.checkboxStyle}
                        checked={addHandling}
                        onChange={(e) => {
                          setAddHandling(e.target.checked);
                          // if check box is unchecked, set handling to 0
                          if (!e.target.checked) {
                            setHandling(0);
                          } else {
                            setHandling(initialHandlingCharge)
                          }
                        }}
                      />
                    }
                    label="Add Handling"
                  />
                </FormGroup>


              </Col>

              <Col md="2">
                <InputNumber
                  className="mt-4"
                  disabled
                  label="Total Shipping Handling"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  value={totalShippingHandling}
                />
              </Col>

              <Col md="2" className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  className="mt-3 mx-3"
                  style={{ maxHeight: 48 }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  className="mt-3"
                  style={{ maxHeight: 48 }}
                  ref={applyButtonRef}
                  onClick={() => {
                    var fa = freightAmount
                    if (localPrepaidOption === PREPAID_OPTIONS.COLLECT || localPrepaidOption === PREPAID_OPTIONS.PREPAID) {
                      fa = 0
                    }
                    applyPackageRaterData(
                      selectedPackageRaterOption,
                      localPrepaidOption,
                      fa,
                      selectedPackageRaterOption.rate,
                      thirdPartyBilling,
                      dataList,
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.shiftKey && e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  disabled={
                    czarZip !== testCzarZip ||
                    destinationZip !== testDestinationZip
                  }
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
}
